import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridColDef } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { DateTime } from 'luxon';

import { useGetQualityImprovementRequestsQuery } from "../api/apiSlice"
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { setToastMessage } from '../uiHelpers/toastSlice';
import { QualityImprovementRequest, QIR_STATUS } from '../api/types';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import { STATUS_COLOURS } from '../uiHelpers/StatusColours';
import styles from './Quality.module.css'

import { setStatusFilter, selectStatusFilter } from './qualitySlice';

export const GetStatusColours = (statusId: number) => {
  let colour = STATUS_COLOURS.Completed;
  if (statusId === QIR_STATUS.New) {
    colour = STATUS_COLOURS.New;
  }
  else if (statusId === QIR_STATUS.Approved) {
    colour = STATUS_COLOURS.Approved;
  }
  else if (statusId === QIR_STATUS.Assigned) {
    colour = STATUS_COLOURS.Assigned;
  }
  else if (statusId === QIR_STATUS.SM_Review) {
    colour = STATUS_COLOURS.SM_Review;
  }
  else if (statusId === QIR_STATUS.CEO_Review) {
    colour = STATUS_COLOURS.CEO_Review;
  }
  else if (statusId === QIR_STATUS.QM_Finalise) {
    colour = STATUS_COLOURS.QM_Finalise;
  }
  else if (statusId === QIR_STATUS.Rejected) {
    colour = STATUS_COLOURS.Rejected;
  }

  return { color: colour + 'ff', backgroundColor: colour + '20' };
};

export const GetDueDateStyle = (expiredDays: number) => {
  return { color: (expiredDays > 0 ? '#ff0000' : '#000000'), fontWeight: (expiredDays > 0 ? '500' : 'normal') };
};

export const enum STATUS_SELECTOR {
  Open = 1,
  New = 2,
  Approved = 3,
  Rejected = 4,
  Assigned = 5,
  SM_Review = 6,
  CEO_Review = 7,
  QM_Finalise = 8,
  Completed = 9,
  All = 10,
};

export const QualityHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const [filter, setFilter] = useState<string>('');
  const statusFilter = useSelector(selectStatusFilter);

  const { data: rawQIRs, error, isLoading, refetch: refetchQIRs } = useGetQualityImprovementRequestsQuery(statusFilter);

  const qirs = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    let records: QualityImprovementRequest[] = [];

    if (rawQIRs) {
      records = rawQIRs;
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.title.toLowerCase().includes(filter.toLowerCase())
        || r.qirNumber.toString().includes(filter.toLowerCase())
        || r.status.toLowerCase().includes(filter.toLowerCase())
      )
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.createDate) newTr.createDate = DateTime.fromISO(newTr.createDate.toString(), { locale: 'en-NZ' });
          if (newTr.dueDate) newTr.dueDate = DateTime.fromISO(newTr.dueDate.toString(), { locale: 'en-NZ' });
          if (newTr.expiredDays > 0) {
            newTr.displayDueDate = newTr.expiredDays === 1 ? newTr.expiredDays + ' day overdue' : newTr.expiredDays + ' days overdue';
          } else if (newTr.dueDate) {
            newTr.displayDueDate = newTr.dueDate.toLocaleString(DateTime.DATE_SHORT); // "ddd Do MMM YYYY"
          }
          return newTr;
        });
    }
    return records;
  }, [rawQIRs, filter]);

  // Always refetch QIR list - @T5286A
  useEffect(() => {
    refetchQIRs();
  }, [refetchQIRs]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleEdit = (qir: QualityImprovementRequest) => {
    if (qir) {
      console.log("Clicked Edit for item ID: " + qir.id);
      const to = '/qir/' + qir.id;
      if (window.location.pathname !== to) {
        navigate(to);
      }
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (params.colDef && params.colDef.headerName !== 'Actions') {
      handleEdit(params.row);
    }
    event.defaultMuiPrevented = true;
  }

  const handleAdd = () => {
    const to = '/newQIR';
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  const columns: GridColDef<QualityImprovementRequest>[] = [
    { field: 'qirNumber', headerName: 'ID', width: 60 },
    { field: 'title', headerName: 'Title', width: 300 },
    {
      field: 'status', headerName: 'Status', width: 120
      , renderCell: ({ row }: GridRenderCellParams<QualityImprovementRequest>) => (
        <div className={styles.status} style={GetStatusColours(row.statusID)} >
          {row.status}
        </div>
      )
    },
    { field: 'assignedTo', headerName: 'Assigned To', width: 140 },
    {
      field: 'displayDueDate', headerName: 'Due Date', width: 160
      , renderCell: ({ row }: GridRenderCellParams<QualityImprovementRequest>) => (
        <span style={GetDueDateStyle(row.expiredDays)} >
          {row.displayDueDate}
        </span>
      )
    },
    { field: 'createUser', headerName: 'Submitted By', width: 140 },
    { field: 'operation', headerName: 'Operation', width: 180 },
    { field: 'location', headerName: 'Location', width: 140 },
    {
      field: 'createDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: DateTime = value;
        return date && date.toLocaleString(DateTime.DATETIME_SHORT);
      }
      , headerName: 'Date Submitted', width: 180
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <span className='pageHeader'>Quality Improvement Requests</span>
        </Grid>
        <Grid sx={{ display: 'flex' }} xs={6}>
          <Stack direction="row" gap={2}>
            <Button variant="contained" className="gridButton"
              disabled={false} onClick={handleAdd}>Add</Button>

            <TextField sx={{ verticalAlign: 'bottom', minWidth: '240px', marginTop: 'auto' }}
              placeholder="search..."
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ margin: '4px' }}>
                    <SearchIcon sx={{ fontSize: '1.4em', color: '#c0c0c0' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton size='small'
                      onClick={() => setFilter('')}
                    >
                      <CloseIcon sx={{ fontSize: '0.8em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={6}>
          <Select variant="standard" sx={{ width: 200, marginLeft: 'auto' }} value={statusFilter} onChange={async (e) => {
            await dispatch(setStatusFilter(Number(e.target.value)));
            refetchQIRs();
          }}>
            <MenuItem key="10" value={STATUS_SELECTOR.All}>All</MenuItem>
            <MenuItem key="1" value={STATUS_SELECTOR.Open}>Open</MenuItem>
            <MenuItem key="2" value={STATUS_SELECTOR.New}>New</MenuItem>
            <MenuItem key="3" value={STATUS_SELECTOR.Approved}>Approved</MenuItem>
            <MenuItem key="4" value={STATUS_SELECTOR.Rejected}>Rejected</MenuItem>
            <MenuItem key="5" value={STATUS_SELECTOR.Assigned}>Assigned</MenuItem>
            <MenuItem key="6" value={STATUS_SELECTOR.SM_Review}>SM Review</MenuItem>
            <MenuItem key="7" value={STATUS_SELECTOR.CEO_Review}>CEO Review</MenuItem>
            <MenuItem key="8" value={STATUS_SELECTOR.QM_Finalise}>QM Finalise</MenuItem>
            <MenuItem key="9" value={STATUS_SELECTOR.Completed}>Completed</MenuItem>
          </Select>
        </Grid>

        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : qirs ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
                  <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={qirs} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>

      </Grid>


    </>
  );
};