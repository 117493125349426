import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequestApp } from "../../auth/authConfig";
//import Button from "react-bootstrap/Button";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  function handleLogin() {
    const targetUrl = window.location.pathname;

    // Trying redirect instead of popup...
    instance.loginRedirect(loginRequestApp).catch(e => {
      console.error(e);
    });

    //navigate("/");
    navigate(targetUrl);
  }

  return (
    <Button variant="outlined" className="gridButton"
      sx={{
        color: '#f5e9d4', borderColor: '#f5e9d4', borderWidth: '2px', borderRadius: '26px', padding: '8px 30px', marginRight: '10px',
        '&:hover': { borderColor: '#f5e9d4', borderWidth: '2px', backgroundColor: '#f5e9d4', color: '#2b302c' }
      }}
      onClick={() => handleLogin()}>
      <span style={{ fontSize: '16px' }}>Log in</span>
    </Button>
  );
}