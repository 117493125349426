import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { DataGrid, GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import VideocamIcon from '@mui/icons-material/Videocam';

import { ResourceUrl } from '../api/types';

import { useGetResourceUrlsQuery } from '../api/apiSlice';

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import { Duration } from "luxon";

export const HelpHome = (props: any) => {
  const { data: helpResources, error, isLoading, refetch: refetchResources } = useGetResourceUrlsQuery();

  useEffect(() => {
    // Always refetch resources to ensure we get a current Sas token in the URLs
    refetchResources();
  }, [refetchResources]);

  const columns: GridColDef<ResourceUrl>[] = [
    {
      field: 'title', headerName: 'Title', width: 500
      , renderCell: ({ row }: GridRenderCellParams<ResourceUrl>) => (
        <Stack direction="row" spacing={1}>
          <VideocamIcon/>
          <a href={row.url} target="_blank" rel="noopener noreferrer"><span>{row.title}</span></a>
        </Stack>
      ),
    },
    { field: 'duration', headerName: 'Duration', width: 120 },
    //{ field: 'fileSize', headerName: 'File Size', type: 'number', width: 120 },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack spacing={1}>
            <Typography variant="h5">Qube Quality: Suggestions to improve our work place</Typography>
            <Typography>Qube is committed to meeting or exceeding customer requirements and creating total
              customer satisfaction with on time performance, order accuracy and maintaining the
              highest quality of service. This is accomplished through our commitment to
              continual improvement of our people, our suppliers, and our processes</Typography>
          </Stack>
        </Grid>
        <Grid xs={12}>
        </Grid>
        <Grid xs={12}>
        <Stack>
            <Typography variant="h5">Help Resources</Typography>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
            ) : helpResources ? (
            <div style={{ display: 'flex', height: 'auto' }}>
              <div style={{ flexGrow: '1' }}>
                    <DataGrid rows={helpResources} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.title}/>
              </div>
            </div>
            ) : null}
          </Stack>
        </Grid>
      </Grid>

    </>
  );
}