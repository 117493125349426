import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grow } from "@mui/material";
import { styled } from '@mui/material/styles';

import { useGetUserPrivilegesQuery } from '../api/apiSlice';
import { hasUserRole } from '../userProfile/userRoleHelper';
import { ROLE } from '../api/types';

export const ConfigurationHome = (props: any) => {
  const [canReadCountryConfig, setCanReadCountryConfig] = useState(false);
  const [canReadLocationConfig, setCanReadLocationConfig] = useState(false);
  const [canReadOperationConfig, setCanReadOperationConfig] = useState(false);

  const { data: userInfo } = useGetUserPrivilegesQuery();

  const navigate = useNavigate();

  useEffect(() => {
    setCanReadCountryConfig(hasUserRole(userInfo, [ROLE.Quality_Manager]));
    setCanReadLocationConfig(hasUserRole(userInfo, [ROLE.Quality_Manager]));
    setCanReadOperationConfig(hasUserRole(userInfo, [ROLE.Quality_Manager]));
  }, [userInfo]);

  const handleNavigate = (target: string) => {
    console.log("handleNavigate: " + target);
    const to = '/configuration/' + target;
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  const cardStyle = { border: '#e0e0e0 solid 1px', boxShadow: 'none', minWidth: "260px", minHeight: "260px", backgroundColor: "#f0f0f0", margin: "0 2em 2em 0" };

  const StyleCaption = styled('span')({ textAlign: "center", marginBottom: "8px", fontWeight: '700' });

  return (
    <>
      <Grid container >
        <Grid sx={{ marginBottom: '12px' }} xs={12}>
          <span className='pageHeader'>Configuration</span>
        </Grid>
        <Grid>
          {canReadCountryConfig || canReadLocationConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Locations</StyleCaption>
                    {canReadCountryConfig ?
                      <Button className="gridButton" onClick={() => handleNavigate("countries")}>Countries</Button>
                      : <></>}
                    {canReadLocationConfig ?
                      <Button className="gridButton" onClick={() => handleNavigate("locations")}>Locations</Button>
                      : <></>}
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
        <Grid>
          {canReadOperationConfig ?
            <Grow in={true}>
              <Card sx={cardStyle}>
                <CardContent>
                  <Stack>
                    <StyleCaption>Operations</StyleCaption>
                    {canReadOperationConfig ?
                      <Button className="gridButton" onClick={() => handleNavigate("operations")}>Operations</Button>
                      : <></>}
                  </Stack>
                </CardContent>
              </Card>
            </Grow>
            : <></>
          }
        </Grid>
      </Grid>
    </>
  );
};