import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";

function handleLogout(instance: any) {
    // Trying redirect instead of popup...
    instance.logoutRedirect().catch((e: any) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = (props: any) => {
    const { instance } = useMsal();

    return (
      <Button className="gridButton" variant="contained" style={{ marginRight: '10px' }} onClick={() => handleLogout(instance)}><span style={{ fontSize: '13px' }}>Log out</span></Button>
    );
}