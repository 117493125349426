import { DateTime, Duration } from 'luxon';

export interface StandardDataItem {
  id: number;
  code: string;
  name: string;
  checked: boolean;
}

export interface SimpleDataItem {
  id: number;
  name: string;
}

export interface User {
  id: string;
  userName: string;
  name: string;
  rolesText: string;
  roles: StandardDataItem[];
  roleIDs: number[];
  lastLoginDate: DateTime;
}

export interface UserWithPrivileges {
  user: User;
  userRoles: number[];
}

export interface QualityImprovementRequest {
  id: number;
  qirNumber: number;
  statusID: number;
  status: string;
  locationID: number;
  location: string;
  operationID: number;
  operation: string;
  assignedToID: string;
  assignedTo: string;
  submittedFor: string;
  title: string;
  description: string;
  dueDate: DateTime;
  createDate: DateTime;
  createUser: string;
  expiredDays: number;
  displayDueDate: string;
}

export interface QIRTask {
  id: number;
  qirid: number;
  taskTypeID: number;
  taskType: string;
  assignedToID: string;
  assignedTo: string;
  instructions: string;
  notes: string;
  dueDate: DateTime;
  isCurrentTask: boolean;
  changeDate: DateTime;
  changeUser: string;
}

export interface QIRDiscussion {
  id: number;
  qirid: number;
  userID: string;
  user: string;
  comment?: string;
  createDate?: DateTime;
  createUser?: string;
}

export interface QualityImprovementRequestDetail {
  id: number;
  qirNumber: number;
  statusID: number;
  status: string;
  locationID: number;
  location: string;
  operationID: number;
  operation: string;
  assignedToID: string;
  assignedTo: string;
  submittedFor: string;
  title: string;
  description: string;
  dueDate: DateTime;
  createDate: DateTime;
  createUser: string;
  expiredDays: number;
  displayDueDate: string;
  tasks: QIRTask[];
}

export interface UpdateQualityImprovementRequestParm {
  id: number;
  statusID: number;
  assignedToID: string;
  dueDate: DateTime;
  taskNotes?: string;
  taskNextInstructions?: string;
}

export interface QIRAttachment {
  id: number;
  qirid: number;
  attachment: string;
  createDate?: DateTime;
  fileSize: number;
  fileSizeText: string;
  url: string;
}

export interface HistoryRecord {
  id: number;
  qirid: number;
  message: string;
  date?: DateTime;
}

export interface StateHistoryRecord {
  id: number;
  qirid: number;
  statusID: number;
  status: string;
  user: string;
  date?: DateTime;
}

//#region Countries and locations
export interface Country {
  id: number;
  name: string;
  code: string;
  changeDate: DateTime;
  changeUser: string;
  changeType: string;
  active: boolean;
}

export interface Location {
  id: number;
  name: string;
  code: string;
  countryID: number;
  country: string;
  timezoneID: string;
  timezone: string;
  locationTypeID: number;
  changeDate: DateTime;
  changeUser: string;
  changeType: string;
  active: boolean;
}
//#endregion Countries and locations

//#region Operations
export interface Operation {
  id: number;
  name: string;
  changeDate: DateTime;
  changeUser: string;
  changeType: string;
  active: boolean;
}
//#endregion Operations

//#region Misc
export interface ResourceUrl {
  title: string;
  url: string;
  fileSize: number;
  duration: string;
}
//#endregion Misc

//#region Enumerations
export enum ROLE {
  None = 0,
  System_Administrator = 1,
  Quality_Manager = 2,
  Senior_Manager = 3,
  CEO = 4,
};

export enum QIR_STATUS {
  New = 1,
  Approved = 2,
  Assigned = 3,
  SM_Review = 4,
  CEO_Review = 5,
  QM_Finalise = 6,
  Completed = 7,
  Rejected = 8,
};

export enum TASK_TYPE {
  Initial_QM_Review = 1,
  Initial_SM_Review = 2,
  Investigation = 3,
  SM_Review = 4,
  CEO_Review = 5,
  QM_Finalise = 6,
  Rejected_Review = 7,
}
//#endregion
