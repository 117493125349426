import { UserWithPrivileges, ROLE } from '../api/types';

export const hasUserRole = (userInfo: UserWithPrivileges | undefined, roles: ROLE[]): boolean => {
  let hasRole: boolean = false;

  if (userInfo && userInfo.userRoles && userInfo.userRoles.length > 0) {
    // Firstly check if the user is a sys admin, if so they get automatic permission
    hasRole = userInfo.userRoles.some(ur => ur === ROLE.System_Administrator);

    if (!hasRole && roles && roles.length > 0) {
      hasRole = userInfo.userRoles.some(ur => roles.some(r => ur === r));
    }
  }
  return hasRole;
}

export const hasUserAnyRole = (userInfo: UserWithPrivileges | undefined): boolean => {
  let hasRole: boolean = false;

  if (userInfo && userInfo.userRoles && userInfo.userRoles.length > 0) {
    hasRole = true;
  }
  return hasRole;
}