import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export const AutocompleteWrapper = (props: any) => {
  const { options, value, onChange, ListboxProps, renderInput, isOptionEqualToValue, ...rest } = props;

  const defaultRenderInput = (params: any) => { return (<TextField {...params} variant="standard" />); };
  const defaultIsOptionEqualToValue = (option: any, value: any) => option.value === value.value;

  return (
    <Autocomplete
      {...rest}
      onChange={(e: any, data: any) => onChange(e, data ? data.value : 0)}
      options={options}
      value={options && value ? options.find((o: any) => o.value === value) : ''}
      ListboxProps={ListboxProps}
      isOptionEqualToValue={isOptionEqualToValue ?? defaultIsOptionEqualToValue}
      renderInput={renderInput ?? defaultRenderInput}
    />);
}

export const MultiSelectWrapper = (props: any) => {
  const { options, value, onChange, input, ...rest } = props;

  const defaultInput = (params: any) => { return (<TextField {...params} variant="standard" />); };

  return (
    <Select
      {...rest}
      multiple
      onChange={(e: any, data: any) => onChange(e, data ? data.value : 0)}
      value={value}
      renderValue={(selected: any) => selected.join(', ')}
      input={input ?? defaultInput}
    >
      {options.map((item: any) => (
        <MenuItem key={item.id} value={item.id}>
          <Checkbox checked={value?.indexOf(item) > -1} />
          <ListItemText primary={item.name} />
        </MenuItem>
      ))}
      </Select>
      );
}