import * as React from "react";
import { useState, memo } from "react";
import styled from "styled-components";
import { Circle } from "rc-progress";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  useItemFinalizeListener,
  useItemProgressListener,
  useAbortItem,
  FILE_STATES
} from "@rpldy/uploady";

const StyledCircle = styled(Circle)`
  width: 18px;
  height: 18px;
  margin: 0 5px;
`;

const ItemProgress = memo((props: any) => {
  const { completed } = useItemProgressListener(props.id) || { completed: 0 };

  return (
    <StyledCircle
      percent={completed}
      strokeWidth={2}
      trailColor="rgb(175,180,176)"
      strokeColor={{
        "0%": "#ffecb1",
        "100%": "#9eea9e"
      }}
    />
  );
});

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  justify-content: flex-start;
`;

const ItemName = styled.span<{ aborted?: boolean, success?: boolean }>`
  display: inline-block;
  margin: 0 10px;

  ${(props: any) => (props.aborted ? "color: gray;" : undefined)}
  ${(props: any) => (props.success ? "color: green;" : undefined)}
`;

export const UploadListItem = (props: any) => {
  const [itemState, setState] = useState(props.item.state);

  const abortItem = useAbortItem();

  useItemFinalizeListener((item) => {
    setState(item.state);
  }, props.item.id);

  const isAborted = itemState === FILE_STATES.ABORTED,
    isSuccess = itemState === FILE_STATES.FINISHED,
    isFinished = ![FILE_STATES.PENDING, FILE_STATES.UPLOADING].includes(
      itemState
    );

  const onAbortItem = () => {
    abortItem(props.item.id);
    if (props.onAttachmentDelete) {
      props.onAttachmentDelete(props.item);
    }
  };

  return (
    <ItemWrapper>
      {!isFinished && <ItemProgress id={props.item.id} />}
      {isAborted &&
        <IconButton size='small' sx={{ margin: 0 }}>
          <CancelIcon sx={{ fontSize: '1em', color: 'gray' }} />
        </IconButton>
      }
      {isSuccess &&
        <IconButton size='small'>
          <CheckCircleOutlineIcon sx={{ fontSize: '1em', color: 'green' }} />
        </IconButton>
      }
      <ItemName aborted={isAborted} success={isSuccess}>
        {props.item.file.name}
      </ItemName>
      {!isFinished &&
        <Tooltip title='Remove attachment' enterDelay={1000}>
          <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
            <IconButton size='small' onClick={onAbortItem}>
              <DeleteIcon sx={{ fontSize: '1.2em', color: 'gray' }} />
            </IconButton>
          </span>
        </Tooltip>
      }
    </ItemWrapper>
  );
};