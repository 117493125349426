import * as React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from 'react-redux';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { ROLE, User, StandardDataItem } from '../api/types';
import { timezones } from '../api/timezones';
import { AutocompleteWrapper, MultiSelectWrapper } from '../uiHelpers/FormikWrappers';
import { useGetUserPrivilegesQuery, useGetUsersQuery, useGetRolesQuery, useUpdateUserRoleMutation } from '../api/apiSlice';
import { hasUserRole } from '../userProfile/userRoleHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './UserAccess.module.css'
import { Stack } from "@mui/material";

export const UserRoleEdit = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [user, setUser] = useState<Partial<User>>({});
  const [isUpdate, setIsUpdate] = useState(false);

  const formikRef = useRef(null);

  const { data: userInfo } = useGetUserPrivilegesQuery();
  const { data: users } = useGetUsersQuery();
  const { data: roles } = useGetRolesQuery();
  const [updateUserRole] = useUpdateUserRoleMutation()

  useEffect(() => {
    if (userInfo) {
      setCanUpdate(hasUserRole(userInfo, [ROLE.System_Administrator]));
    }
  }, [userInfo])

  useEffect(() => {
    setUser(props.user);
    setIsUpdate(props.user && props.user.id && props.user.id.length > 0);
  }, [props.user])

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Formik validation schema
  const validationSchema = yup.object().shape({
    id: yup.string()
      .required('User is required'),
    roleIDs: yup.array()
      .required('One or more roles must be selected')
      .min(1, 'At least one role must be selected'),
  });

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();

  const handleSave = async (newValues: Partial<User>) => {
    //displayToastMessage("warning", "Save", "Not yet implemented");

    if (newValues && users) {
      // In case this user is not already locally defined, we need to add name and userName to the user object passed
      // to the web API call...
      let newUser = { ...newValues };
      const user = users.find(u => u.id === newValues.id);
      if (user) {
        newUser.name = user.name;
        newUser.userName = user.userName;
      }

      const res: any = await updateUserRole(newUser);
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        handleClose();
        displayToastMessage("info", "Success", "User roles saved");
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving user roles", errorText);
      }
    }
  }

  const captionStyle = { margin: '6px 0 4px 0' };

  const handleRolesChanged = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;

    if (Array.isArray(value)) {
      setUser({ ...user, roleIDs: (value as number[]) ?? [] });
    }
  };

return (
  <>
    <Dialog
      PaperProps={{ sx: { width: "420px" } }}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isUpdate ? (
          <span className="dialogHeader">Update User Role</span>
        )
          : (
            <span className="dialogHeader">Add User Role</span>
          )
        }
      </DialogTitle>
      <DialogContent>
        <div style={{ margin: 'auto' }}>
          <Formik initialValues={user} enableReinitialize validateOnMount innerRef={formikRef} onSubmit={handleSave} validationSchema={validationSchema}>
            {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      <span style={captionStyle}>User</span>
                      <AutocompleteWrapper
                        disabled={isUpdate || !canUpdate}
                        value={values?.id}
                        onChange={(e: any, value: any) => {
                          if (value) {
                            setFieldValue("id", value, true);
                            setUser({ ...user, id: value });
                          }
                        }}
                        renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '100%' }} variant="standard" />); }}
                        options={users ? users.map((j) => { return { label: j.name, value: j.id }; }) : []}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <span style={captionStyle}>Role(s)</span>
                      <Select
                        multiple
                        value={values?.roleIDs ?? []}
                        onChange={handleRolesChanged}
                        input={<Input />}
                        renderValue={(selected) => selected.map((s: any) => roles?.find((r: any) => r.id === s)?.name).join(', ')}
                        disabled={!canUpdate}
                      >
                        {roles?.map((role: StandardDataItem) => (
                          <MenuItem key={role.id} value={role.id}>
                            <Checkbox checked={values?.roleIDs?.find((ur: number) => ur === role.id) !== undefined} />
                            <ListItemText primary={role.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '8px' }}>

                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                      <Grid item style={{ marginLeft: '16px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '16px' }}>
                        <Button
                          className={styles.gridButton}
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!canUpdate || isSubmitting || !isValid}
                        >
                          {props.user && props.user.id && props.user.id.length > 0 ? (
                            <span>Update</span>
                          )
                            : (
                              <span>Add</span>
                            )
                          }
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  </>
);

};