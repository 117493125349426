import { createSlice } from '@reduxjs/toolkit';
import { STATUS_SELECTOR } from './QualityHome';

interface QualityStore {
  statusFilter: number,
}

const initialState: QualityStore = {
  statusFilter: STATUS_SELECTOR.All,
};


export const qualitySlice = createSlice({
  name: 'quality',
  initialState,
  reducers: {
    setStatusFilter: (state: QualityStore, action) => {
      if (action.payload) {
        state.statusFilter = action.payload;
      } else {
        state.statusFilter = initialState.statusFilter;
      }
    },
  },
});

export const { setStatusFilter } = qualitySlice.actions;

export const selectStatusFilter = (state: any) => state.quality.statusFilter;

export default qualitySlice.reducer;