import React, { useState } from "react";
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';

import { useSelector } from 'react-redux';
import { SignOutButton } from "../signin/SignOutButton";
import { selectProfileName, selectProfileEmail, selectProfileInitials } from "./profileSlice"


export const getInitials = (text: string) => {
  var names = text.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// Avatar colour functions
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string, size: string = "32px", fontSize: string = "1em") {
  return {
    sx: {
      bgcolor: stringToColor(name),
      borderWidth: "0",
      height: size,
      width: size,
      minHeight: size,
      minWidth: size,
      fontSize: fontSize
    }
  };
}

/**
 * Renders information about the user obtained from Microsoft Graph
 */
export const ProfileData = (props: any) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const name = useSelector(selectProfileName);
  const initials = useSelector(selectProfileInitials);
  const email = useSelector(selectProfileEmail);

  return (
    <>
      <Avatar {...stringAvatar(name)} component={Button} onClick={handleShow}>{initials}</Avatar>

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
            alignItems: "flex-start"
          }
        }}
        PaperProps={{
          sx: {
            m: 0,
            top: 70,
            right: 10
          }
        }}
        //PaperProps={{ sx: { position: "fixed", top: 70, right: 10, m: 0 } }}
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ width: '350px', padding: '16px 24px 8px 24px' }}>
          <span className="dialogHeader">{name}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{email}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="gridButton" onClick={handleClose} autoFocus><span style={{ fontSize: '13px' }}>Cancel</span></Button>
          <SignOutButton />
        </DialogActions>
      </Dialog>
    </>
  );
};