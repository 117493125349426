import * as React from 'react';
import Button from '@mui/material/Button';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    //  flexGrow: 1
  },
  logo: {
    width: 160,
  },
  body: {
    fontSize: 12,
    fontWeight: 'normal',
  },
  bold: {
    fontSize: 12,
    fontWeight: 'bold',
  },
});


// Create Document Component
export const QIRCompletedPDF = (props: any) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.body}>Reference QIR {props.qirId}: {props.title}</Text>
          <Text style={styles.body}>&nbsp;</Text>
          <Image style={styles.logo} src={{ uri: "/api/data/getLogo", method: "GET", headers: { 'Content-Type': 'image/jpeg' }, body: '' }} />
        </View>
        <View style={styles.section}>
          <Text style={styles.body}>To {props.submitter}</Text>
          <Text style={styles.body}>&nbsp;</Text>
          <Text style={styles.body}>Thank you for entering the Quality improvement submitted by {props.submitter} on {props.date}.</Text>
          <Text style={styles.body}>&nbsp;</Text>
          <Text style={styles.body}>The process is now complete. Your suggestion has been reviewed by Senior Management and the following has taken place.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.body}>{props.message}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.body}>Please pass the outcome to all concerned.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.body}>Regards,</Text>
          <Text style={styles.body}>&nbsp;</Text>
          <Text style={styles.bold}>Chris Bell</Text>
          <Text style={styles.bold}>Quality Manager</Text>
          <Text style={styles.bold}>Qube Ports NZ</Text>
        </View>
      </Page>
    </Document>
  );
};

export const QIRCompletedPDFLink = (props: any) => {
  const filename = "QIR" + props.qirId + ".pdf";

  return (
    <PDFDownloadLink document={<QIRCompletedPDF qirId={props.qirId} title={props.title} submitter={props.submitter} date={props.date} message={props.message} />}
      fileName={filename}>
      {({ blob, url, loading, error }) =>
        <Button variant="outlined" className="gridButton">
          Download PDF
        </Button>
      }
    </PDFDownloadLink>
  );
};