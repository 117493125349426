import { createSlice } from '@reduxjs/toolkit';

interface ProfileState {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    initials: string,
    status: string
};

const initialState: ProfileState = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    initials: '',
    status: 'idle',
};

const getInitials = (text: string) => {
    var names = text.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            if (action.payload) {
                state.id = action.payload.id;
                state.firstName = action.payload.givenName;
                state.lastName = action.payload.surname;
                state.email = action.payload.userPrincipalName;
                state.initials = getInitials(action.payload.givenName + ' ' + action.payload.surname);
            } else {
                state = initialState;
            }
        },
    },
});

export const { setProfile } = profileSlice.actions;

export const selectProfileName = (state: any) => state.profile.firstName + ' ' + state.profile.lastName;
export const selectProfileEmail = (state: any) => state.profile.email;
export const selectProfileInitials = (state: any) => state.profile.initials;

export default profileSlice.reducer;