import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { DateTime } from 'luxon';

import { QIRTask, QualityImprovementRequestDetail, User } from '../api/types';
import { GetTaskEditParameters, TaskEditParameters } from './taskHelper';

import styles from './Quality.module.css'

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#e0e0e0',
  fontWeight: 700,
  minHeight: 32,
  maxHeight: 32,
  '&.Mui-expanded': {
    minHeight: 32,
    maxHeight: 32,
    backgroundColor: '#e0e0e0',
  }
});

const StyledTextViewDiv = styled('div')({
  whiteSpace: 'pre-wrap',
});


export interface QIRTaskEditProps {
  qir: QualityImprovementRequestDetail;
  assignedToUser: User;
  currentQirStatusId: number;
  nextQirStatusId: number;
  task: QIRTask;
  previousTask: QIRTask | undefined;
  onNextInstructionsChange(value: string): any;
  onNotesChange(value: string): any;
  highlightNotes: boolean;
  highlightInstructions: boolean;
};

export const QIRTaskEdit = (props: QIRTaskEditProps) => {
  const [taskNotes, setTaskNotes] = useState<string>('');
  const [taskNextInstructions, setTaskNextInstructions] = useState<string>('');

  const viewParms = useMemo((): TaskEditParameters | undefined => {
    if (props?.task) {
      return GetTaskEditParameters(props.qir, props.assignedToUser, props.previousTask?.assignedToID, props.task, props.currentQirStatusId ?? 0
        , props.nextQirStatusId ?? 0);
    }
    return undefined;
  }, [props.qir, props.assignedToUser, props.task, props.currentQirStatusId, props.nextQirStatusId, props.previousTask]);

  // Note specific props.task dependency.  Using just props would mean the affect firing for other props changes as well, thus resetting notes each time
  useEffect(() => {
    if (props?.task) {
      setTaskNotes(props.task.notes);
    }
  }, [props.task]);

  const handleNotesChanged = (value: any) => {
    setTaskNotes(value ? value.toString() : '');
    props.onNotesChange(value ? value.toString() : '');
  }

  const handleNextInstructions = (value: any) => {
    setTaskNextInstructions(value ? value.toString() : '');
    props.onNextInstructionsChange(value ? value.toString() : '');
  }

  return (
    <div>
      {(props.task && viewParms && viewParms.show) ?
        <Accordion expanded={true}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>{viewParms.title}</span>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {viewParms.showAssignedTo ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.assignedToLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <span>{props.task.assignedTo}</span>
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showInstructions ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.instructionsLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <StyledTextViewDiv>{props.task.instructions}</StyledTextViewDiv>
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showDueDate ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.dueDateLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <span>{props.task.dueDate?.toLocaleString(DateTime.DATE_SHORT)}</span>
                  </Grid>
                </>
                : <></>
              }

              {viewParms.showNotes ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.notesLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <TextField value={taskNotes}
                      placeholder={viewParms.notesPlaceHolder}
                      onChange={(e: any) => { handleNotesChanged(e.target.value); }}
                      multiline
                      rows={viewParms.notesHeight ?? 4}
                      fullWidth
                      className={props.highlightNotes ? styles.hintHighlight : ''}
                    />
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showNextInstructions ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.nextInstructionsLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <TextField value={taskNextInstructions}
                      placeholder={viewParms.nextInstructionsPlaceHolder}
                      onChange={(e: any) => { handleNextInstructions(e.target.value) }}
                      multiline
                      rows={4}
                      fullWidth
                      className={props.highlightInstructions ? styles.hintHighlight : ''}
                    />
                  </Grid>
                </>
                : <></>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
        : <></>
      }
    </div>
  );
}