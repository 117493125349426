import * as React from "react";
import { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";

export interface ToastMessageValue {
  severity: AlertColor;
  header?: string;
  body: string;
  timestamp: number;
}

const isToastMessageValue = (p: any): p is ToastMessageValue => {
  return (p as ToastMessageValue) !== undefined
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ToastMessage = (props: any) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (isToastMessageValue(props.message) && props.message.body && props.message.body.length > 0) {
      setShowToast(true);
    }
  }, [props.message]);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={showToast} autoHideDuration={3000} onClose={() => setShowToast(false)}>
        { isToastMessageValue(props.message)
          ?
          <Alert onClose={() => setShowToast(false)} severity={props.message.severity} sx={{ width: '100%' }}>
            {props.message.header && props.message.header.length > 0 ? <AlertTitle>{props.message.header}</AlertTitle> : <></>}
            {props.message.body}
          </Alert>
          :
          <></>
        }
      </Snackbar>
    </>
  );
}