import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItemIcon from '@mui/material/ListItemIcon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { ProfileData } from "./userProfile/ProfileData";
import { QualityHome } from "./quality/QualityHome";
import { QualityImprovementRequestAdd } from "./quality/QualityImprovementRequestAdd";
import { QualityImprovementRequestEdit } from "./quality/QualityImprovementRequestEdit";
import { UserHome } from "./userAccess/UserHome";
import { ConfigurationHome } from "./configuration/ConfigurationHome";
import { CountryHome } from './configuration/CountryHome';
import { LocationHome } from './configuration/LocationHome';
import { OperationHome } from './configuration/OperationHome';
import { HelpHome } from './help/HelpHome';

import { useGetUserPrivilegesQuery } from './api/apiSlice';
import { ToastMessage } from './uiHelpers/ToastMessage';
import { selectToastMessage } from './uiHelpers/toastSlice';
import { hasUserRole } from './userProfile/userRoleHelper';
import { ROLE } from './api/types';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const NavMenuItem = (props: any) => {
  const navigate = useNavigate();

  const itemSelected = (eventKey: string) => {
    console.log("Item selected with eventKey = " + eventKey);
    const to = '/' + eventKey;
    if (window.location.pathname !== to) {
      navigate(to);
    }
  };

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton onClick={() => itemSelected(props.eventKey)}
        sx={{
          minHeight: 48,
          justifyContent: props.open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {props.children}
        </ListItemIcon>
        <ListItemText primary={props.text} sx={{ opacity: props.open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export const PageLayout = (props: any) => {
  const theme = useTheme();
  const { data: userInfo } = useGetUserPrivilegesQuery();
  const [open, setOpen] = useState(false);
  const [showUserNav, setShowUserNav] = useState(false);
  const [showConfigNav, setShowConfigNav] = useState(false);

  const toastMessage = useSelector(selectToastMessage);

  useEffect(() => {
    setShowUserNav(hasUserRole(userInfo, [ROLE.System_Administrator]));
    setShowConfigNav(hasUserRole(userInfo, [ROLE.Quality_Manager]));
  }, [userInfo]);

  useEffect(() => {
    console.log('PageLayout: A Toast message has been set... body = ' + toastMessage.body)
  }, [toastMessage]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            {open ?
              <MenuOpenIcon /> : <MenuIcon />
            }
          </IconButton>
          <Typography sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontSize: '1.6em' }} noWrap component="div">
            Qube Quality{props.testSystemName ? ' - ' + props.testSystemName : ''}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <ProfileData />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ minHeight: "48px !important" }}>
        </DrawerHeader>
        <List>
          <NavMenuItem key="1" eventKey="" open={open} text="Quality Improvement">
            <AssignmentTurnedInIcon />
          </NavMenuItem>
          {showUserNav ? (
            <NavMenuItem key="2" eventKey="users" open={open} text="User Management">
              <PeopleIcon />
            </NavMenuItem>
          ) : (<></>)}
          {showConfigNav ? (
            <NavMenuItem key="3" eventKey="configuration" open={open} text="Configuration">
              <SettingsIcon />
            </NavMenuItem>
          ) : (<></>)}
          <NavMenuItem key="1" eventKey="help" open={open} text="Help">
            <HelpIcon />
          </NavMenuItem>
        </List>
      </Drawer>
      <Box component="main" m={2.4} sx={{ flexGrow: 1 }}>
        <DrawerHeader sx={{ minHeight: "48px !important" }} />
        <Routes>
          <Route path='/' element={<QualityHome />} />
          <Route path='newQIR' element={<QualityImprovementRequestAdd />} />
          <Route path='qir/:id' element={<QualityImprovementRequestEdit />} />
          <Route path='users' element={<UserHome />} />
          <Route path='configuration' element={<ConfigurationHome />} />
          <Route path='configuration/countries' element={<CountryHome />} />
          <Route path='configuration/locations' element={<LocationHome />} />
          <Route path='configuration/operations' element={<OperationHome />} />
          <Route path='help' element={<HelpHome />} />
        </Routes>

        {props.children}
      </Box>

      <ToastMessage message={toastMessage} />
    </Box >
  );

};