import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AlertColor } from "@mui/material/Alert";
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

const initialState: ToastMessageValue = {
  severity: 'info',
  header: '',
  body: '',
  timestamp: 0,
};


export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastMessage: (state: ToastMessageValue, action) => {
      if (action.payload) {
        state.severity = action.payload.severity;
        state.header = action.payload.header;
        state.body = action.payload.body;
        // NOTE: The timestamp ensures each toast message is always unique
        state.timestamp = Date.now();
      } else {
        state = initialState;
      }
    },
  },
});

export const { setToastMessage } = toastSlice.actions;

export const selectToastMessage = (state: any) => state.toast;

export default toastSlice.reducer;