// MSAL configuration settings come from the runtime-config.js file, loaded in index.html from the base URL directory
const { clientId, tenantId, redirectUri, apiScopes } = window['runConfig'];

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: "https://login.microsoftonline.com/" + tenantId, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// NOTE: We use separate login configurations for the MS Graph and Application calls.
//       The reason for this is that the presence of MS Graph API scope "User.Read" results in a "nonce" value in the JWT header
//       and this "nonce" causes the Application Web API to reject the JWT with an "Invalid signature" error (and HTTP 401) !!!!

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequestMSGraph = {
    scopes: ["User.Read"]
};

// Add scopes here for ID token to be used Mahi Tahi Web API endpoints.
export const loginRequestApp = {
    scopes: apiScopes
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMePhoto: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value"
};