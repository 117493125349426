import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { AlertColor } from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { MentionsInput, Mention } from 'react-mentions'

import { QIRDiscussion, UserWithPrivileges } from '../api/types';
import { useUpdateQIRDiscussionMutation, useDeleteDiscussionMutation, useGetUsersQuery } from '../api/apiSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { setToastMessage } from '../uiHelpers/toastSlice';
import mentionsInputStyle from '../uiHelpers/MentionsInputStyle';
import { Stack, Typography } from "@mui/material";
import { DateTime } from 'luxon';

import { getInitials, stringAvatar } from '../userProfile/ProfileData';
import { DiscussionDelete } from './DiscussionDelete';

export interface DiscussionEditProps {
  discussion: QIRDiscussion;
  canEdit: boolean;
  userInfo?: UserWithPrivileges;
  onSaved?(hasSaved: boolean): any;
  isHighlit: boolean;
};

export const DiscussionEdit = (props: DiscussionEditProps) => {
  const dispatch = useDispatch();

  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);

  const [updateQIRDiscussion, { isLoading: isUpdating }] = useUpdateQIRDiscussionMutation();
  const [deleteDiscussion, { isLoading: isDeleting }] = useDeleteDiscussionMutation();
  const { data: rawAllUsers } = useGetUsersQuery();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Mentions style
  const MentionsInputStyle = {
    control: {
      backgroundColor: (props.isHighlit ? '#ffffa0' : '#fff'),
      //fontSize: 14,
      fontWeight: 'normal',
    },
    '&readOnly': {
    },
    '&multiLine': {
      control: {
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        border: '1px solid silver',
        borderRadius: '4px',
      },
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },

    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        //fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  };



  // User list for mentions
  const users = useMemo(() => {
    if (rawAllUsers) {
      return rawAllUsers.map((u) => { return { id: u.id, display: u.name }; });
    }
    return undefined;
  }, [rawAllUsers]);

  // Formik validation schema
  const validationSchema = yup.object().shape({
    comment: yup.string()
      .required('A comment is required'),
  });

  useEffect(() => {
    if (props) {
      setCanEdit(props.canEdit);
      setCanDelete(props.discussion?.id > 0 && props.canEdit);
    }
  }, [props]);

  const handleSave = async (newValues: QIRDiscussion, actions: any) => {
    if (newValues) {
      const res: any = await updateQIRDiscussion(newValues);
      // If all is well there should be some data returned (with a return code)
      const newQirId: number = res.data;
      if (newQirId !== undefined && newQirId > 0) {
        displayToastMessage("info", "Comment saved", "QIR comment saved successfully");
        actions.resetForm();

        if (props.onSaved) {
          props.onSaved(true);
        }
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving comment", errorText);
      }
    }
  }
  const handleDelete = () => {
    setShowItemDelete(true);
  };

  return (
    <div>
      {
        props && props.discussion ?
          <>
            <Grid container spacing={1} sx={{ marginBottom: '4px' }} >
              <Grid xs={12}>
                <Stack direction="row">
                  <div style={{ marginTop: '4px' }}>
                    {props.discussion.id === 0 && props.userInfo && props.userInfo.user ?
                      // It's a new discussion item, so use the current signed-in user for the avatar
                      <Avatar {...stringAvatar(props.userInfo.user.name, "32px", "13px")} >
                        {getInitials(props.userInfo.user.name)}</Avatar>
                      :
                      // Use the user of the discussion
                      <div style={{ marginTop: '18px' }}>
                        <Avatar {...stringAvatar((props.discussion.user ? props.discussion.user : 'Unknown'), "32px", "13px")} >
                          {props.discussion.user && getInitials(props.discussion.user)}</Avatar>
                      </div>
                    }
                  </div>
                  <Grid xs={12}>
                    {canEdit ?
                      <Formik initialValues={props.discussion} enableReinitialize={true} onSubmit={handleSave} validationSchema={validationSchema}>
                        {({ errors, handleSubmit, handleChange, setFieldValue, touched, values, resetForm, isSubmitting, isValid, dirty }) => (
                          <Form>
                            <Grid container alignItems="flex-start" spacing={1}>
                              <Grid xs={12}>
                                <Stack spacing={0}>
                                  {props.discussion.id > 0 ?
                                    <Stack direction="row" spacing={1}>
                                      <Typography sx={{ fontWeight: 700 }}>{props.discussion.user}</Typography>
                                      <Typography>commented</Typography>
                                      <Typography>{props.discussion.createDate && DateTime.fromISO(props.discussion.createDate.toString(), { locale: 'en-NZ' }).toLocaleString(DateTime.DATETIME_MED)}</Typography>
                                    </Stack>
                                    : <></>}
                                  <MentionsInput style={MentionsInputStyle} value={values.comment} name="comment"
                                    onChange={(e: any, value: any) => { setFieldValue("comment", value, true); }}
                                    placeholder="Add a comment. Use @ to mention a person">
                                    <Mention style={{ color: '#0080ff' }} trigger="@" data={users ?? []} displayTransform={(id: any, display: any) => "@" + display} />
                                  </MentionsInput>
                                </Stack>
                              </Grid>
                              {dirty ?
                                <Grid xs={12}>
                                  <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ display: 'flex' }}>
                                    <Grid style={{ marginLeft: '16px' }}>
                                      <Button
                                        className="gridButton"
                                        size="small"
                                        type="button"
                                        disabled={!dirty}
                                        onClick={() => resetForm()}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                    <Grid style={{ marginLeft: '16px' }}>
                                      <Button
                                        className="gridButton"
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || !isValid || !dirty}
                                      >
                                        <span>Save</span>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                : <></>}

                            </Grid>
                          </Form>
                        )}
                      </Formik>
                      :
                      <div style={{ minHeight: '60px', }}>
                        <Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography sx={{ fontWeight: 700 }}>{props.discussion.user}</Typography>
                            <Typography>commented</Typography>
                            <Typography>{props.discussion.createDate && DateTime.fromISO(props.discussion.createDate.toString(), { locale: 'en-NZ' }).toLocaleString(DateTime.DATETIME_MED)}</Typography>
                          </Stack>
                          <MentionsInput readOnly style={mentionsInputStyle} value={props.discussion.comment}>
                            <Mention style={{ color: '#0080ff' }} trigger="@" data={users ?? []} displayTransform={(id: any, display: any) => "@" + display} />
                          </MentionsInput>
                        </Stack>
                      </div>
                    }
                  </Grid>
                  {canDelete ?
                    <Tooltip title="Delete comment" enterDelay={1000}>
                      <IconButton sx={{ height: '28px', marginTop: '20px' }} color="primary" size="small"
                        onClick={handleDelete}>
                        <DeleteIcon sx={{ fontSize: '1.2em' }} />
                      </IconButton>
                    </Tooltip>
                    :
                    <IconButton sx={{ height: '28px', opacity: '0' }} size="small">
                      <DeleteIcon sx={{ fontSize: '1.2em' }} />
                    </IconButton>
                  }
                </Stack>
              </Grid>
            </Grid>

            <DiscussionDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} discussion={props?.discussion} />
          </>
          : <></>
      }
    </div >
  );
}