import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Uploady from "@rpldy/uploady";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequestMSGraph, loginRequestApp } from "./auth/authConfig";

import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated } from "@azure/msal-react";

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


// This stuff will be removed from here and some of the logic moved elsewhere...
import { callMsGraphUserProfile, callMsGraphUserPhoto } from "./features/userProfile/graph";

import { setProfile } from "./features/userProfile/profileSlice"

import './App.css';
import { PageLayout } from "./features/PageLayout";
import { LandingPage } from "./features/landing/LandingPage"

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontSize: 11,
  },
  palette: {
    primary: {
      main: '#404040',
      light: '#b0b0b0',
      dark: '#202020'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '4px 6px',
        }
      }
    },
  },
});

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  const { testSystemName } = (window as any)['runConfig'];

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequestMSGraph,
        account: accounts[0]
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
        //console.log("Access token for MS-Graph call: " + response.accessToken);
        callMsGraphUserProfile(response.accessToken).then(response => dispatch(setProfile(response)));
      }).catch((e) => {
        console.log("Silent request for access token for MS Graph failed: " + e);
        instance.acquireTokenPopup(request).then((response) => {
          //console.log("Access token for MS-Graph call (catch): " + response.accessToken);
          callMsGraphUserProfile(response.accessToken).then(response => dispatch(setProfile(response)));
        });
      });
    }
  }, [isAuthenticated, instance, accounts, dispatch]);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <UnauthenticatedTemplate>
            <LandingPage />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Uploady destination={{ url: '/api/data/' }} autoUpload={false} >
              <PageLayout testSystemName={testSystemName}>
              </PageLayout>
            </Uploady>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
