import * as React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AlertColor } from '@mui/material/Alert';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import {
  useUploady,
  useBatchAddListener,
  useBatchFinalizeListener,
  useRequestPreSend,
} from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import UploadButton from "@rpldy/upload-button";

import { ROLE, QualityImprovementRequest, Location, Operation } from '../api/types';
import { AutocompleteWrapper } from '../uiHelpers/FormikWrappers';
import { useAddQualityImprovementRequestMutation, useGetLocationsQuery, useGetOperationsQuery, getAccessToken } from '../api/apiSlice';
import { hasUserRole } from '../userProfile/userRoleHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { UploadListItem } from "../attachments/AttachmentListItem";

import styles from './Quality.module.css'
import { Stack } from "@mui/material";

const StyleUploadButton = styled(UploadButton)({
  fontFamily: 'Open Sans',
  margin: '4px',
});

const ListContainer = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginTop: '4px',
});

export const QualityImprovementRequestAdd = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<string>('');
  const [qirId, setQirId] = useState<number>(0);
  const { data: rawLocations } = useGetLocationsQuery();
  const { data: rawOperations } = useGetOperationsQuery();
  const [addQualityImprovementRequest, { isLoading: isUpdating }] = useAddQualityImprovementRequestMutation();

  const { processPending } = useUploady();
  const [attachments, setAttachments] = useState([]);

  const handleAttachmentDeleted = (item: any) => {
    const idx = attachments?.findIndex((a: any) => a.id === item?.id);
    if (idx > -1) {
      let revisedAttachments = attachments.slice(); // Copy the array
      revisedAttachments.splice(idx, 1); // Remove the deleted item (in-place)
      setAttachments(revisedAttachments);
    }
  };

  useEffect(() => {
    if (qirId > 0) {

      if (attachments && attachments.length > 0) {
        // We have some attachments, so they must be uploaded. The batch-finish listener will handle closing the page
        console.log("Processing " + attachments.length + " attachments");
        processPending();
      } else {
        // No attachments, so just close
        displayToastMessage("info", "Success", "Quality improvement request saved");
        handleClose();
      }
    }
  }, [qirId, attachments]);

  useBatchAddListener((batch: any) => {
    setAttachments((attachments: any) => attachments.concat(batch.items));
  });

  useBatchFinalizeListener((batch: any) => {
    console.log("Batch finished with state = " + batch.state);
    if (batch?.loaded > 0) {
      displayToastMessage("info", "Success", "Quality improvement request saved (with attachments)");
      handleClose();
    } else {
      // We've arrived here because all attachments have been deleted, so we need to clear the attachments, otherwise they'll interfere with the save process
      setAttachments([]);
    }
  });

  useRequestPreSend((props: any) => {
    return {
      options: {
        destination: {
          url: "/api/data/AddQirAttachments/" + qirId,
          headers: { "Authorization": `Bearer ${accessToken}` }
        }
      }
    };

  });


  const locations = useMemo(() => {
    let records: Location[] = [];
    if (rawLocations) {
      records = rawLocations.filter((r) => r.active === true);
    }
    return records;
  }, [rawLocations]);

  const operations = useMemo(() => {
    let records: Operation[] = [];
    if (rawOperations) {
      records = rawOperations.filter((r) => r.active === true);
    }
    return records;
  }, [rawOperations]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  useEffect(() => {
    async function fetchToken() {
      const token = await getAccessToken();
      setAccessToken(token);
    }
    fetchToken();
  }, []);

  // Formik validation schema
  const validationSchema = yup.object().shape({
    title: yup.string()
      .required('Title is required'),
    description: yup.string()
      .required('Description is required'),
    locationID: yup.number()
      .min(1)
      .required('Location is required'),
    operationID: yup.number()
      .min(1)
      .required('Operation is required'),
  });

  const handleClose = () => navigate("/");

  const handleSave = async (newValues: Partial<QualityImprovementRequest>) => {
    if (newValues) {
      const res: any = await addQualityImprovementRequest(newValues);
      // If all is well there should be some data returned (with a return code)
      const newQirId: number = res.data;
      if (newQirId !== undefined && newQirId > 0) {
        setQirId(newQirId);
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving quality improvement request", errorText);
      }
    }
  }

  const captionStyle = { margin: '6px 0 4px 0', fontSize: '13px' };

  const newQIR: Partial<QualityImprovementRequest> = { id: 0, title: '', description: '', submittedFor: '', locationID: 0, operationID: 0 };

  return (
    <>
      <div style={{ margin: 'auto' }}>
        <Formik initialValues={newQIR} onSubmit={handleSave} validationSchema={validationSchema}>
          {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
            <Form>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <span className="dialogHeader">New Quality Improvement Request</span>
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <span style={captionStyle}>Title</span>
                    <TextField value={values?.title}
                      name="title"
                      onChange={handleChange}
                      variant="standard"
                      sx={{ width: '420px' }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <span style={captionStyle}>Submitted For (optional)</span>
                    <TextField value={values.submittedFor}
                      name="submittedFor"
                      onChange={handleChange}
                      variant="standard"
                      sx={{ width: '420px' }}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <span style={captionStyle}>Location</span>
                    <AutocompleteWrapper
                      value={values?.locationID}
                      onChange={(e: any, value: any) => { if (value) setFieldValue("locationID", value, true); }}
                      renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '420px' }} variant="standard" />); }}
                      options={locations ? locations.map((j) => { return { label: j.name, value: j.id }; }) : []}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <span style={captionStyle}>Operation</span>
                    <AutocompleteWrapper
                      value={values?.operationID}
                      onChange={(e: any, value: any) => { if (value) setFieldValue("operationID", value, true); }}
                      renderInput={(params: any) => { return (<TextField {...params} sx={{ width: '420px' }} variant="standard" />); }}
                      options={operations ? operations.map((j) => { return { label: j.name, value: j.id }; }) : []}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <span style={captionStyle}>Description</span>
                    <TextField value={values.description}
                      name="description"
                      multiline
                      fullWidth
                      rows={4}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <span className="dialogHeader">Attachments</span>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ marginTop: '8px' }}>
                    <Stack>
                      <div>
                      </div>
                      <UploadDropZone
                        onDragOverClassName="drag-over"
                        grouped={true}
                        maxGroupSize={3}
                        autoUpload={false}
                        className={styles.dropZone}
                      >
                        <StyleUploadButton autoUpload={false} grouped={true}
                          extraProps={{ type: 'button' }}>Select File(s)</StyleUploadButton>
                        <span style={{ margin: '4px', fontSize: '13px'}}>or drag & drop file(s) here</span>
                      </UploadDropZone>
                    </Stack>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ marginTop: '8px' }}>
                    <Stack>
                      <span style={captionStyle}>Selected file(s)</span>
                      <ListContainer>
                        {attachments.map((item: any) => (
                          <UploadListItem key={item.id} item={item} onAttachmentDelete={handleAttachmentDeleted} />
                        ))}
                      </ListContainer>
                    </Stack>
                  </div>
                </Grid>


                <Grid item xs={12} style={{ marginTop: '8px' }}>
                  <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end">
                    <Grid item style={{ marginLeft: '16px' }}>
                      <Button
                        className={styles.gridButton}
                        type="button"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item style={{ marginLeft: '16px' }}>
                      <Button
                        className={styles.gridButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        <span>Submit</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );

};