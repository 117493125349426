import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel'
import { AlertColor } from "@mui/material/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import { DateTime } from 'luxon';

import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import { useGetUserPrivilegesQuery, useGetOperationsQuery } from '../api/apiSlice';
import { hasUserRole } from '../userProfile/userRoleHelper';
import { ROLE, Operation } from '../api/types';
import { OperationDelete } from './OperationDelete';
import { OperationEdit } from "./OperationEdit";

import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './Configuration.module.css'

export const OperationHome = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<Operation> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);
  const [includeInactive, setIncludeInactive] = useState<boolean>(false);

  const { data: userInfo } = useGetUserPrivilegesQuery();
  const { data: rawOperations, error, isLoading } = useGetOperationsQuery();

  useEffect(() => {
    if (userInfo) {
      setCanUpdate(hasUserRole(userInfo, [ROLE.Quality_Manager]));
      setCanDelete(hasUserRole(userInfo, [ROLE.Quality_Manager]));
    }
  }, [userInfo])

  const operations = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    let records: Operation[] = [];
    if (rawOperations) {
      records = rawOperations.filter((r) => includeInactive || r.active === true);
      // Set dates to be moment objects (they're strings when returned from the web service call...)
      records = records.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()))
        .map((tr) => {
          let newTr = { ...tr };
          if (newTr.changeDate) newTr.changeDate = DateTime.fromISO(newTr.changeDate.toString(), { locale: 'en-NZ' });
          return newTr;
        });
    }
    return records;
  }, [rawOperations, filter, includeInactive]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleAdd = () => {
    setSelectedItem({ id: 0, name: '' });
    setShowItemEdit(true);
  };

  const handleEdit = (item: Operation) => {
    if (item) {
      setSelectedItem(item);
      setShowItemEdit(true);
    }
  };

  const handleDelete = (item: Operation) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const columns: GridColDef<Operation>[] = [
    { field: 'name', headerName: 'Name', width: 320 },
    { field: 'active', headerName: 'Active', type: 'boolean', width: 100 },
    {
      field: 'changeDate', type: 'dateTime'
      , valueFormatter: ({ value }: any) => {
        let date: DateTime = value;
        return date && date.toLocaleString(DateTime.DATETIME_SHORT);
      }
      , headerName: 'Last Updated', width: 180
    },
    { field: 'changeUser', headerName: 'Updated By', width: 220 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, align: 'center', headerAlign: 'center'
      , renderCell: ({ row }: GridRenderCellParams<Operation>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
              <IconButton disabled={!canUpdate} color="primary" size="small"
                onClick={() => handleEdit(row)}>
                <EditIcon sx={{ fontSize: '1.2em' }} />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title={row.active ? 'Delete' : 'Activate'} enterDelay={1000}>
            <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
              <IconButton disabled={row.active ? !canDelete : !canUpdate} color="primary" size="small"
                onClick={() => handleDelete(row)}>
                {row.active ? (<DeleteIcon sx={{ fontSize: '1.2em' }} />) : (<RestoreIcon sx={{ fontSize: '1.2em' }} />)}
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid sx={{ margin: '0 8px' }} container spacing={2}>
        <Grid xs={12}>
          <span className='pageHeader'>Operations</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={12}>
          <Stack direction="row" gap={2}>
            <Button variant="contained" className={styles.gridButton}
              disabled={!canUpdate} onClick={handleAdd}>Add</Button>

            <TextField sx={{ verticalAlign: 'bottom', minWidth: '240px', marginTop: 'auto' }}
              placeholder="search operation"
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ margin: '4px' }}>
                    <SearchIcon sx={{ fontSize: '1.4em', color: '#c0c0c0' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton size='small'
                      onClick={() => setFilter('')}
                    >
                      <CloseIcon sx={{ fontSize: '0.8em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControlLabel
              sx={{ height: '24px', marginLeft: '16px', marginTop: 'auto' }}
              value="activeOnly"
              control={<Switch color="primary" checked={includeInactive}
                onChange={(val: any) => setIncludeInactive(val.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />}
              label="Show inactive"
              labelPlacement="start"
            />
          </Stack>
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : operations ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={operations} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <OperationDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} operation={selectedItem} />
      <OperationEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} operation={selectedItem} />
    </>
  );
}