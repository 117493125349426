import * as React from "react";

import { TASK_TYPE, QIR_STATUS, ROLE, SimpleDataItem, QualityImprovementRequestDetail, User, QIRTask } from '../api/types';
import { Typography } from "@mui/material";
import { DateTime } from 'luxon';

export const GetApplicableStatuses = (statusId: number): SimpleDataItem[] => {
  let statuses = [] as SimpleDataItem[];

  if (statusId === QIR_STATUS.New) {
    statuses = [
      { id: QIR_STATUS.New, name: "New" },
      { id: QIR_STATUS.Approved, name: "Approved" },
      { id: QIR_STATUS.Rejected, name: "Rejected" },
    ];
  }
  else if (statusId === QIR_STATUS.Approved) {
    statuses = [
      { id: QIR_STATUS.Approved, name: "Approved" },
      { id: QIR_STATUS.Assigned, name: "Assign to investigator" },
    ];
  }
  else if (statusId === QIR_STATUS.Assigned) {
    statuses = [
      { id: QIR_STATUS.Assigned, name: "Assigned" },
      { id: QIR_STATUS.SM_Review, name: "SM Review" },
    ];
  }
  else if (statusId === QIR_STATUS.SM_Review) {
    statuses = [
      { id: QIR_STATUS.SM_Review, name: "SM Review" },
      { id: QIR_STATUS.CEO_Review, name: "CEO Review" },
      { id: QIR_STATUS.Assigned, name: "Assign to investigator" },
    ];
  }
  else if (statusId === QIR_STATUS.CEO_Review) {
    statuses = [
      { id: QIR_STATUS.CEO_Review, name: "CEO Review" },
      { id: QIR_STATUS.SM_Review, name: "SM Review" },
      { id: QIR_STATUS.QM_Finalise, name: "QM Finalise" },
    ];
  }
  else if (statusId === QIR_STATUS.QM_Finalise) {
    statuses = [
      { id: QIR_STATUS.QM_Finalise, name: "QM Finalise" },
      { id: QIR_STATUS.Completed, name: "Completed" },
    ];
  }
  else if (statusId === QIR_STATUS.Completed) {
    statuses = [
      { id: QIR_STATUS.Completed, name: "Completed" },
    ];
  }
  else if (statusId === QIR_STATUS.Rejected) {
    statuses = [
      { id: QIR_STATUS.Rejected, name: "Rejected" },
      { id: QIR_STATUS.QM_Finalise, name: "QM Finalise" },
      { id: QIR_STATUS.New, name: "New" },
    ];
  }
  return statuses;
};

export interface ActionHighlight {
  user: boolean;
  state: boolean;
  instruction: boolean;
  note: boolean;
  comment: boolean;
}

export interface ActionHint {
  hint: React.ReactElement;
  isEmptyHint: boolean;
  highlight: ActionHighlight;
}

const NoHighlight: ActionHighlight = { user: false, state: false, instruction: false, note: false, comment: false };

export const GetNextActionHint = (qir: QualityImprovementRequestDetail | undefined, taskTypeId: number, nextStatusId: number
  , assignedToUser: User | undefined, previousAssignedToUserID: string | undefined, isTextEntered: boolean): ActionHint => {
  const emptyHint: React.ReactElement = <></>;
  let hint: React.ReactElement = emptyHint;
  let isEmptyHint: boolean = true;
  let highlight: ActionHighlight = { ...NoHighlight };

  if (qir && taskTypeId && nextStatusId) {
    if (taskTypeId === TASK_TYPE.Initial_QM_Review) {
      if (nextStatusId === QIR_STATUS.New) {
        hint = <span>Change <strong>State</strong> to approve or reject the QIR</span>;
        highlight.state = true;
      }
      else if (nextStatusId === QIR_STATUS.Approved) {
        if (!assignedToUser?.roles?.find((r) => r.id === ROLE.Senior_Manager)) {
          hint = <span>Assign to <strong>Senior Manager</strong> to review</span>;
          highlight.user = true;
        } else {
          if (isTextEntered) {
            hint = emptyHint; // Ready to save, so remove hint
          } else {
            hint = <span>Enter Initial Comments</span>;
            highlight.instruction = true;
          }

        }
      }
      else if (nextStatusId === QIR_STATUS.Rejected) {
        if (!assignedToUser?.roles?.find((r) => r.id === ROLE.CEO)) {
          hint = <span>Assign to <strong>CEO</strong> to review</span>;
        } else {
          if (isTextEntered) {
            hint = emptyHint; // Ready to save, so remove hint
          } else {
            hint = <span>Enter reason for rejection</span>;
            highlight.instruction = true;
          }
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.Initial_SM_Review) {
      if (nextStatusId === QIR_STATUS.Approved) {
        if (qir?.assignedToID !== assignedToUser?.id) {
          hint = emptyHint; // New user selected to assign to a different SM, remove hint
        } else {
          hint = <span>Change State to <strong>Assign to investigator</strong> to assign</span>;
          highlight.state = true;
        }
      }
      else if (nextStatusId === QIR_STATUS.Assigned) {
        if (qir?.assignedToID !== assignedToUser?.id) {
          if (isTextEntered) {
            hint = emptyHint; // Ready to save, so remove hint
          } else {
            hint = <span>Enter instructions to the Investigator</span>;
            highlight.instruction = true;
          }
        } else {
          hint = <span>Assign to <strong>investigator</strong> to complete investigation</span>;
          highlight.user = true;
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.Investigation) {
      if (nextStatusId === QIR_STATUS.Assigned) {
        if (isTextEntered) {
          hint = <span>Change State to <strong>SM Review</strong> to submit investigation</span>;
          highlight.state = true;
        } else {
          hint = <span>Enter investigation notes</span>;
          highlight.note = true;
        }
      }
      else if (nextStatusId === QIR_STATUS.SM_Review) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          hint = <span>Enter investigation notes</span>;
          highlight.note = true;
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.SM_Review) {
      if (nextStatusId === QIR_STATUS.SM_Review) {
        hint = <span>Change State to <strong>CEO Review</strong> to approve investigation</span>;
        highlight.state = true;
      }
      else if (nextStatusId === QIR_STATUS.CEO_Review) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          hint = <span>Enter recommendation</span>;
          highlight.note = true;
        }
      }
      else if (nextStatusId === QIR_STATUS.Assigned) {
        if (qir?.assignedToID !== assignedToUser?.id) {
          if (assignedToUser?.id === previousAssignedToUserID) {
            hint = <span>Enter a <strong>comment</strong> to the investigator or reassign to a new investigator</span>;
            highlight.comment = true;
            highlight.user = true;
          } else {
            hint = <span>Enter <strong>instructions</strong> to the new investigator</span>;
            highlight.instruction = true;
          }
        } else {
          hint = <span>Enter comment to investigator or reassign to a new investigator</span>;
          highlight.comment = true;
          highlight.user = true;
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.CEO_Review) {
      if (nextStatusId === QIR_STATUS.CEO_Review) {
        hint = <span>Change State to <strong>QM Finalise</strong> to close investigation</span>;
        highlight.state = true;
      }
      else if (nextStatusId === QIR_STATUS.QM_Finalise) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          if (!assignedToUser?.roles?.find((r) => r.id === ROLE.Quality_Manager)) {
            hint = <span>Assign to <strong>Quality Manager</strong> & enter CEO review notes</span>;
            highlight.state = true;
            highlight.note = true;
          } else {
            hint = <span>Enter CEO review notes</span>;
            highlight.note = true;
          }
        }
      }
      else if (nextStatusId === QIR_STATUS.SM_Review) {
        if (isTextEntered) {
          hint = emptyHint;
        } else {
          if (assignedToUser?.id === previousAssignedToUserID) {
            hint = <span>Enter <strong>comment</strong> to Senior Manager</span>;
            highlight.comment = true;
          } else {
            hint = <span>Enter <strong>instructions</strong> to the new Senior Manager</span>;
            highlight.instruction = true;
          }
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.QM_Finalise) {
      if (nextStatusId === QIR_STATUS.QM_Finalise) {
        hint = <span>Change State to <strong>Completed</strong> to close QIR</span>;
        highlight.state = true;
      }
      else if (nextStatusId === QIR_STATUS.Completed) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          hint = <Typography>Enter email feedback to QIR originator</Typography>;
          highlight.note = true;
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.Rejected_Review) {
      if (nextStatusId === QIR_STATUS.Rejected) {
        hint = <span>Change State to <strong>QM Finalise</strong> to approve no futher action</span>;
        highlight.state = true;
      }
      else if (nextStatusId === QIR_STATUS.QM_Finalise) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          hint = <span>Enter CEO review comments</span>;
          highlight.note = true;
        }
      }
      else if (nextStatusId === QIR_STATUS.New) {
        if (isTextEntered)
          hint = emptyHint;
        else {
          hint = <span>Enter CEO review comments</span>;
          highlight.note = true;
        }
      }
    }
  }

  isEmptyHint = hint?.type === emptyHint?.type;

  return { hint: hint, isEmptyHint: isEmptyHint, highlight: highlight };
};

export interface TaskEditParameters {
  show: boolean;
  title: string;
  assignedToLabel?: string;
  showAssignedTo: boolean;
  nextInstructionsLabel?: string;
  nextInstructionsPlaceHolder?: string;
  showNextInstructions: boolean;
  instructionsLabel?: string;
  showInstructions: boolean;
  notesLabel?: string;
  notesPlaceHolder?: string;
  showNotes: boolean;
  notesHeight?: number;
  dueDateLabel?: string;
  showDueDate: boolean;
};

export const GetTaskEditParameters = (qir: QualityImprovementRequestDetail | undefined, assignedToUser: User | undefined, previousAssignedToId: string | undefined
  , task: QIRTask, currentStatusId: number, nextStatusId: number): TaskEditParameters => {


  const taskTypeId: number = task?.taskTypeID ?? 0;

  // For a QM initial review task and a QIR status of New, the task edit is not shown
  let editParms: TaskEditParameters = {
    show: false, title: "Task", showAssignedTo: false, showNextInstructions: false, showInstructions: false
    , showNotes: false, showDueDate: false
  };

  if (taskTypeId && nextStatusId) {
    if (taskTypeId === TASK_TYPE.Initial_QM_Review) {
      const show = nextStatusId && nextStatusId === QIR_STATUS.New ? false : true;
      const nextInstructionsLabel = nextStatusId && nextStatusId === QIR_STATUS.Approved ? "Instructions to Senior Manager" : "Justification to CEO";
      const nextInstructionsPlaceHolder = nextStatusId && nextStatusId === QIR_STATUS.Approved ? "Enter instructions to Senior Manager" : "Enter reason for rejection";
      editParms = {
        show: show, title: "Initial Review"
        , nextInstructionsLabel: nextInstructionsLabel, nextInstructionsPlaceHolder: nextInstructionsPlaceHolder, showNextInstructions: true
        , showAssignedTo: false, showInstructions: false, showNotes: false, showDueDate: false
      };
    }
    else if (taskTypeId === TASK_TYPE.Initial_SM_Review) {
      const show = nextStatusId && nextStatusId === QIR_STATUS.Assigned ? true : false;
      editParms = {
        show: show, title: "Investigation"
        , showNextInstructions: true, nextInstructionsLabel: "Instructions to Investigator", nextInstructionsPlaceHolder: "Enter your instructions to the investigator"
        , showAssignedTo: false, showInstructions: false, showNotes: false, showDueDate: false
      };
    }
    else if (taskTypeId === TASK_TYPE.Investigation) {
      editParms = {
        show: true, title: "Investigation"
        , showAssignedTo: true, assignedToLabel: "Investigator Assigned"
        , showInstructions: true, instructionsLabel: "Instructions to Investigator"
        , showDueDate: true, dueDateLabel: "Investigation Due"
        , showNotes: true, notesLabel: "Investigation Outcome"
        , notesHeight: 8
        , notesPlaceHolder: "Enter your findings and recommended actions. Change state to SM Reveiw to submit investigation."
        , showNextInstructions: false
      };
    }
    else if (taskTypeId === TASK_TYPE.SM_Review) {
      if (nextStatusId) {
        //const show = nextStatusId && nextStatusId === QIR_STATUS.SM_Review ? false : true;
        const hasInstructions = task?.instructions?.length > 0;

        if (nextStatusId === QIR_STATUS.SM_Review) {
          if (hasInstructions) {
            // Show just the instructions - none of the editable fields...
            editParms = {
              show: true, title: "Senior Manager Review"
              , instructionsLabel: "Instructions to Senior Manager", showInstructions: true
              , showNotes: false, showNextInstructions: false, showAssignedTo: false, showDueDate: false
            };
          }
        } else if (nextStatusId === QIR_STATUS.CEO_Review) {
          editParms = {
            show: true, title: "Senior Manager Review"
            , notesLabel: "Recommendation", notesPlaceHolder: "Enter your recommended actions.Change State to CEO Review to submit QIR", showNotes: true
            , instructionsLabel: "Instructions to Senior Manager", showInstructions: hasInstructions
            , showNextInstructions: false, showAssignedTo: false, showDueDate: false
          };
        } else if (nextStatusId === QIR_STATUS.Assigned) { // Sending back or reassigning for further investigation
          if (qir?.assignedToID !== assignedToUser?.id && assignedToUser?.id !== previousAssignedToId) {
            editParms = { // Reassigning to a different investigator
              show: true, title: "Investigation"
              , showNextInstructions: true, nextInstructionsLabel: "Instructions to Investigator", nextInstructionsPlaceHolder: "Enter your instructions to the investigator"
              , showAssignedTo: false, showInstructions: false, showNotes: false, showDueDate: false
            };
          }
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.CEO_Review) {
      if (nextStatusId) {
        if (nextStatusId === QIR_STATUS.QM_Finalise) {
          editParms = {
            show: true, title: "CEO Review"
            , notesLabel: "Notes", notesPlaceHolder: "Enter your review notes", showNotes: true
            , showNextInstructions: false, showAssignedTo: false, showInstructions: false, showDueDate: false
          };
        } else if (nextStatusId === QIR_STATUS.SM_Review) {
          if (qir?.assignedToID !== assignedToUser?.id && assignedToUser?.id !== previousAssignedToId) {
            editParms = { // Reassigning to a different SM
              show: true, title: "Senior Manager Review"
              , showNextInstructions: true, nextInstructionsLabel: "Instructions to SM", nextInstructionsPlaceHolder: "Enter your instructions to the senior manager"
              , showAssignedTo: false, showInstructions: false, showNotes: false, showDueDate: false
            };
          }

        }
      }
    }
    else if (taskTypeId === TASK_TYPE.QM_Finalise) {
      if (nextStatusId) {
        const show = nextStatusId && nextStatusId === QIR_STATUS.Completed && currentStatusId !== nextStatusId ? true : false;
        if (nextStatusId === QIR_STATUS.Completed) {
          editParms = {
            show: show, title: "Feedback to QIR Originator"
            , notesLabel: "Email Message", notesPlaceHolder: "Enter a message for the QIR originator", showNotes: true
            , showNextInstructions: false, showAssignedTo: false, showInstructions: false, showDueDate: false
          };
        }
      }
    }
    else if (taskTypeId === TASK_TYPE.Rejected_Review) {
      if (nextStatusId) {
        const show = nextStatusId && currentStatusId !== nextStatusId ? true : false;
        if (nextStatusId === QIR_STATUS.QM_Finalise) {
          editParms = {
            show: show, title: "CEO Review"
            , notesLabel: "Notes", notesPlaceHolder: "Enter your review notes", showNotes: true
            , showNextInstructions: false, showAssignedTo: false, showInstructions: false, showDueDate: false
          };
        }
        else if (nextStatusId === QIR_STATUS.New) {
          editParms = {
            show: show, title: "CEO Review"
            , notesLabel: "Notes", notesPlaceHolder: "Enter your review notes", showNotes: true
            , showNextInstructions: false, showAssignedTo: false, showInstructions: false, showDueDate: false
          };
        }
      }
    }
  }
  return editParms;
};

export interface TaskViewParameters {
  show: boolean;
  title: string;
  assignedToLabel?: string;
  showAssignedTo: boolean;
  instructionsLabel?: string;
  showInstructions: boolean;
  notesLabel?: string;
  showNotes: boolean;
  dueDateLabel?: string;
  showDueDate: boolean;
  expanded?: boolean;
};

export const GetTaskViewParameters = (taskTypeId: number, qirStatusId: number): TaskViewParameters => {
  // For a QM initial review task and a QIR status of New, the task edit is not shown
  let viewParms: TaskViewParameters = { show: false, title: "", showAssignedTo: false, showInstructions: false, showNotes: false, showDueDate: false };
  if (taskTypeId === TASK_TYPE.Initial_QM_Review) {
    //  if (qirStatusId === QIR_STATUS.Rejected) {
    //    viewParms.show = true;
    //    viewParms.title = "Initial Review";
    //    viewParms.instructionsLabel = "QM Initial Comment";
    //    viewParms.showInstructions = true;
    //  }
  }
  else if (taskTypeId === TASK_TYPE.Initial_SM_Review) {
    viewParms.show = true;
    viewParms.title = "Initial Review";
    viewParms.instructionsLabel = "QM Initial Comment";
    viewParms.showInstructions = true;
    viewParms.assignedToLabel = "Senior Manager Assigned";
    viewParms.showAssignedTo = true;
    viewParms.dueDateLabel = "Assign for Investigation By";
    viewParms.showDueDate = true;
    viewParms.expanded = qirStatusId === QIR_STATUS.CEO_Review;
  }
  else if (taskTypeId === TASK_TYPE.Investigation) {
    viewParms.show = true;
    viewParms.title = "Investigation";
    viewParms.assignedToLabel = "Investigator Assigned";
    viewParms.showAssignedTo = true;
    viewParms.instructionsLabel = "Instructions to Investigator";
    viewParms.showInstructions = true;
    viewParms.dueDateLabel = "Investigation Due";
    viewParms.showDueDate = true;
    viewParms.notesLabel = "Investigation Outcome";
    viewParms.showNotes = true;
  }
  else if (taskTypeId === TASK_TYPE.SM_Review) {
    if (qirStatusId !== QIR_STATUS.Assigned) {
      viewParms.show = true;
      viewParms.title = "Senior Manager Recommendation";
      viewParms.assignedToLabel = "Senior Manager";
      viewParms.showAssignedTo = true;
      viewParms.notesLabel = "Recommendation";
      viewParms.showNotes = true;
    }
  }
  else if (taskTypeId === TASK_TYPE.CEO_Review) {
    if (qirStatusId === QIR_STATUS.QM_Finalise || qirStatusId === QIR_STATUS.Completed) {
      viewParms.show = true;
      viewParms.title = "CEO Review";
      viewParms.assignedToLabel = "CEO";
      viewParms.showAssignedTo = true;
      viewParms.notesLabel = "Notes";
      viewParms.showNotes = true;
    }
  }
  else if (taskTypeId === TASK_TYPE.Rejected_Review) {
    if (qirStatusId === QIR_STATUS.New || qirStatusId === QIR_STATUS.QM_Finalise) {
      viewParms.show = true;
      viewParms.title = "CEO Review";
      viewParms.assignedToLabel = "CEO";
      viewParms.showAssignedTo = true;
      viewParms.notesLabel = "CEO Comment";
      viewParms.showNotes = true;
      viewParms.instructionsLabel = "QM Reject Reason";
      viewParms.showInstructions = true;
    }
    else if (qirStatusId === QIR_STATUS.Rejected) {
      viewParms.show = true;
      viewParms.title = "Initial Review";
      viewParms.instructionsLabel = "QM Reject Reason";
      viewParms.showInstructions = true;
    }
    else if (qirStatusId === QIR_STATUS.Completed) {
      viewParms.show = true;
      viewParms.title = "Initial Review";
      viewParms.instructionsLabel = "QM Reject Reason";
      viewParms.showInstructions = true;
      viewParms.notesLabel = "CEO Comment";
      viewParms.showNotes = true;
    }
  }
  else if (taskTypeId === TASK_TYPE.QM_Finalise) {
    if (qirStatusId === QIR_STATUS.Completed) {
      viewParms.show = true;
      viewParms.title = "Feedback to QIR Originator";
      viewParms.assignedToLabel = "Quality Manager";
      viewParms.showAssignedTo = true;
      viewParms.notesLabel = "Email Message";
      viewParms.showNotes = true;
    }
  }

  return viewParms;
};

export const IsTaskValid = (qir: QualityImprovementRequestDetail | undefined, targetQirStatusId: number, assignedToUser: User, previousAssignedToId: string | undefined
  , taskNotes: string, taskNextInstructions: string, commentsAdded: boolean): boolean => {
  let isValid = false;
  if (qir && assignedToUser && targetQirStatusId) {
    const currentQirStatusId: number = qir?.statusID;

    if (currentQirStatusId === targetQirStatusId) {
      // Always valid if the QIR state is not changing - this allows assigned-to and due date to be updated (by authorised users)...
      isValid = true;
    }
    else if (currentQirStatusId === QIR_STATUS.New) {
      if (targetQirStatusId === QIR_STATUS.Approved || targetQirStatusId === QIR_STATUS.Rejected) {
        isValid = (taskNextInstructions != null && taskNextInstructions.length > 0);
      }
    }
    else if (currentQirStatusId === QIR_STATUS.Approved) {
      if (targetQirStatusId === QIR_STATUS.Assigned) {
        isValid = (taskNextInstructions != null && taskNextInstructions.length > 0);
      }
      else {
        isValid = true;  // State still the same - this is allowed as an SM can re-assign to another SM
      }
    }
    else if (currentQirStatusId === QIR_STATUS.Assigned) {
      if (targetQirStatusId === QIR_STATUS.SM_Review) {
        isValid = (taskNotes != null && taskNotes.length > 0);
      }
    }
    else if (currentQirStatusId === QIR_STATUS.SM_Review) {
      if (targetQirStatusId === QIR_STATUS.CEO_Review) {
        isValid = (taskNotes != null && taskNotes.length > 0);
      }
      else if (targetQirStatusId === QIR_STATUS.Assigned) {
        if (assignedToUser?.id !== previousAssignedToId) { // New investigator, so new instructions required
          isValid = (taskNextInstructions != null && taskNextInstructions.length > 0);
        } else {
          isValid = commentsAdded; // Sending back to investigator requires comments (in the Discussion section)
        }
      }
    }
    else if (currentQirStatusId === QIR_STATUS.CEO_Review) {
      if (targetQirStatusId === QIR_STATUS.QM_Finalise) {
        isValid = (taskNotes != null && taskNotes.length > 0);
      }
      else if (targetQirStatusId === QIR_STATUS.SM_Review) {
        if (assignedToUser?.id !== previousAssignedToId) { // New SM, so new instructions required
          isValid = (taskNextInstructions != null && taskNextInstructions.length > 0);
        } else {
          isValid = commentsAdded; // Sending back to SM requires comments (in the Discussion section)
        }
      }
    }
    else if (currentQirStatusId === QIR_STATUS.QM_Finalise) {
      if (targetQirStatusId === QIR_STATUS.Completed) {
        isValid = (taskNotes != null && taskNotes.length > 0);
      }
    }
    else if (currentQirStatusId === QIR_STATUS.Rejected) {
      if (targetQirStatusId === QIR_STATUS.QM_Finalise || targetQirStatusId === QIR_STATUS.New) {
        isValid = (taskNotes != null && taskNotes.length > 0);
      }
    }
  }
  return isValid;
}

//#region Misc helpers
export const AddWorkingDays = (date: DateTime, numDays: number) => {
  let daysIncremented: number = 0;
  let incrementedDate: DateTime = date;
  if (date) {
    while (daysIncremented < numDays) {
      incrementedDate = incrementedDate.plus({ days: 1 });
      // Increment the count only non-weekend days
      if (parseInt(incrementedDate.toFormat('c'), 10) < 6) { // 6, 7 = Sat, Sun
        daysIncremented++;
      }
    }
    return incrementedDate;
  }
  return DateTime.now();
};
//#endregion