import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequestApp, msalConfig } from "../../auth/authConfig";

import {
  User, UserWithPrivileges, QualityImprovementRequest, QualityImprovementRequestDetail, Country, Location, Operation, StandardDataItem
  , QIRTask, UpdateQualityImprovementRequestParm, QIRDiscussion, QIRAttachment, ResourceUrl, HistoryRecord, StateHistoryRecord
} from './types'

const msalInstance = new PublicClientApplication(msalConfig);


export const getAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();

  const request = {
    ...loginRequestApp,
    account: accounts[0]
  };

  const response = await msalInstance.acquireTokenSilent(request);

  //console.log("Access token for App call: " + response.accessToken);
  return response.accessToken;
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/data/',
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['User', 'QIR', 'Country', 'Location', 'Operation', 'Discussion', 'Attachment'],
  endpoints: (builder) => ({
    //#region Users
    getUserPrivileges: builder.query<UserWithPrivileges, void>({
      query: () => 'getUserPrivileges',
    }),
    getRoles: builder.query<StandardDataItem[], void>({
      query: () => 'getRoles',
    }),
    getUsers: builder.query<User[], void>({
      query: () => 'getUsers',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'User', id } as const)),
            { type: 'User', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'NEWID' }` is invalidated
          [{ type: 'User', id: 'NEWID' }],
    }),
    getLocalUsersWithRoles: builder.query<User[], void>({
      query: () => 'getLocalUsersWithRoles',
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'User', id } as const)),
            { type: 'User', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'NEWID' }` is invalidated
          [{ type: 'User', id: 'NEWID' }],
    }),

    updateUserRole: builder.mutation<void, Partial<User>>({
      query: (item) => ({
        url: 'updateUserRole',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'User', id }, { type: 'User', id: 'NEWID' }],
    }),

    deleteAllUserRoles: builder.mutation<void, string>({
      query: (id) => ({
        url: 'deleteAllUserRoles/' + id,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'User', id }],
    }),
    //#endregion Users

    //#region QIR
    getQualityImprovementRequests: builder.query<QualityImprovementRequest[], number | void>({
      query: (statusFilter) => `getQualityImprovementRequests?statusFilter=${statusFilter}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'QIR', id } as const)),
            { type: 'QIR', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'QIR', id: 'NEWID' }],
    }),

    getQualityImprovementRequest: builder.query<QualityImprovementRequestDetail, number>({
      query: (id) => `getQualityImprovementRequest/${id}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            { type: 'QIR', id: result.id },
            { type: 'QIR', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'QIR', id: 'NEWID' }],
    }),

    getDiscussions: builder.query<QIRDiscussion[], number>({
      query: (id) => `getDiscussions/${id}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Discussion', id } as const)),
            { type: 'Discussion', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'Discussion', id: 'NEWID' }],
    }),

    getQirAttachments: builder.query<QIRAttachment[], number>({
      query: (id) => `getQirAttachments/${id}`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Attachment', id } as const)),
            { type: 'Attachment', id: 'NEWID' }, // -1 for newly added records
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'RoleSecurables', id: 'NEWID' }` is invalidated
          [{ type: 'Attachment', id: 'NEWID' }],
    }),

    addQualityImprovementRequest: builder.mutation<void, Partial<QualityImprovementRequest>>({
      query: (item) => ({
        url: 'addQualityImprovementRequest',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'QIR', id }, { type: 'QIR', id: 'NEWID' }],
    }),

    updateQIRTask: builder.mutation<void, Partial<QIRTask>>({
      query: (item) => ({
        url: 'updateQIRTask',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'QIR', id }, { type: 'QIR', id: 'NEWID' }],
    }),

    updateQIRDiscussion: builder.mutation<void, Partial<QIRDiscussion>>({
      query: (item) => ({
        url: 'updateQIRDiscussion',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Discussion', id }, { type: 'Discussion', id: 'NEWID' }],
    }),

    updateQualityImprovementRequest: builder.mutation<void, Partial<UpdateQualityImprovementRequestParm>>({
      query: (item) => ({
        url: 'updateQualityImprovementRequest',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'QIR', id }, { type: 'QIR', id: 'NEWID' }],
    }),

    deleteAttachment: builder.mutation<void, number>({
      query: (id) => ({
        url: 'deleteAttachment/' + id,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'Attachment', id }],
    }),

    deleteDiscussion: builder.mutation<void, number>({
      query: (id) => ({
        url: 'deleteDiscussion/' + id,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'Discussion', id }],
    }),

    getQirHistory: builder.query<HistoryRecord[], number>({
      query: (id) => `getQirHistory/${id}`,
      providesTags: (result) =>
        result && result.length > 0
          ? // Successful query
          [
            { type: 'QIR', id: result[0].qirid },
            { type: 'Discussion', id: result[0].qirid },
            { type: 'Attachment', id: result[0].qirid },
          ]
          : // an error occurred
          [],
    }),

    getQirStateHistory: builder.query<StateHistoryRecord[], number>({
      query: (id) => `getQirStateHistory/${id}`,
      providesTags: (result) =>
        result && result.length > 0
          ? // Successful query
          [
            { type: 'QIR', id: result[0].qirid },
            { type: 'Discussion', id: result[0].qirid },
            { type: 'Attachment', id: result[0].qirid },
          ]
          : // an error occurred
          [],
    }),

    //#endregion QIR

    //#region Countries and Locations
    getCountries: builder.query<Country[], void>({
      query: () => `getCountries`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Country', id } as const)),
            { type: 'Country', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Country', id: 'NEWID' }` is invalidated
          [{ type: 'Country', id: 'NEWID' }],
    }),

    updateCountry: builder.mutation<void, Partial<Country>>({
      query: (item) => ({
        url: 'updateCountry',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Country', id }, { type: 'Country', id: 'NEWID' }],
    }),

    toggleDeleteCountry: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteCountry/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Country', id }],
    }),

    getLocations: builder.query<Location[], void>({
      query: () => `getLocations`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Location', id } as const)),
            { type: 'Location', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Location', id: 'NEWID' }` is invalidated
          [{ type: 'Location', id: 'NEWID' }],
    }),

    updateLocation: builder.mutation<void, Partial<Location>>({
      query: (item) => ({
        url: 'updateLocation',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: item,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Location', id }, { type: 'Location', id: 'NEWID' }],
    }),

    toggleDeleteLocation: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteLocation/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Location', id }],
    }),
    //#endregion Countries and Locations

    //#region Operations
    getOperations: builder.query<Operation[], void>({
      query: () => `getOperations`,
      providesTags: (result) =>
        result
          ? // Successful query
          [
            ...result.map(({ id }) => ({ type: 'Operation', id } as const)),
            { type: 'Operation', id: 'NEWID' }, // -1 for newly added records
          ]
          : // // an error occurred, but we still want to refetch this query when `{ type: 'Operation', id: 'NEWID' }` is invalidated
          [{ type: 'Operation', id: 'NEWID' }],
    }),

    updateOperation: builder.mutation<void, Partial<Operation>>({
      query: (category) => ({
        url: 'updateOperation',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: category,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Operation', id }, { type: 'Operation', id: 'NEWID' }],
    }),

    toggleDeleteOperation: builder.mutation<void, { id: number, targetState: boolean }>({
      query: (parm) => ({
        url: 'toggleDeleteOperation/' + parm.id + '?targetState=' + parm.targetState,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Operation', id }],
    }),
    //#endregion Operations

    //#region Misc
    getResourceUrls: builder.query<ResourceUrl[], void>({
      query: () => `getResourceUrls`,
    }),
    //#endregion
  }),
});

export const {
  useGetUserPrivilegesQuery
  , useGetUsersQuery
  , useGetLocalUsersWithRolesQuery
  , useGetRolesQuery
  , useUpdateUserRoleMutation
  , useDeleteAllUserRolesMutation
  , useGetQualityImprovementRequestsQuery
  , useGetQualityImprovementRequestQuery
  , useGetDiscussionsQuery
  , useGetQirAttachmentsQuery
  , useAddQualityImprovementRequestMutation
  , useUpdateQIRTaskMutation
  , useUpdateQIRDiscussionMutation
  , useUpdateQualityImprovementRequestMutation
  , useDeleteAttachmentMutation
  , useDeleteDiscussionMutation
  , useGetQirHistoryQuery
  , useGetQirStateHistoryQuery
  , useGetCountriesQuery
  , useUpdateCountryMutation
  , useToggleDeleteCountryMutation
  , useGetLocationsQuery
  , useUpdateLocationMutation
  , useToggleDeleteLocationMutation
  , useGetOperationsQuery
  , useUpdateOperationMutation
  , useToggleDeleteOperationMutation
  , useGetResourceUrlsQuery
} = apiSlice;