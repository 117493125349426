import * as React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridColDef } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AlertColor } from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import ReactPDF from '@react-pdf/renderer';

import {
  useUploady,
  useBatchAddListener,
  useBatchFinalizeListener,
  useRequestPreSend,
  useAbortAll,
} from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import UploadButton from "@rpldy/upload-button";

import {
  QIRTask, TASK_TYPE, QualityImprovementRequestDetail, QIR_STATUS, ROLE, User, SimpleDataItem, UpdateQualityImprovementRequestParm
  , QIRDiscussion, QIRAttachment
} from '../api/types';
import {
  GetApplicableStatuses, GetNextActionHint, IsTaskValid, AddWorkingDays
} from './taskHelper';

import {
  useGetUserPrivilegesQuery, useGetQualityImprovementRequestQuery, useGetUsersQuery, useGetLocalUsersWithRolesQuery
  , useUpdateQualityImprovementRequestMutation, useGetDiscussionsQuery, useGetQirAttachmentsQuery, getAccessToken
} from '../api/apiSlice';
import { hasUserRole, hasUserAnyRole } from '../userProfile/userRoleHelper';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { STATUS_COLOURS } from '../uiHelpers/StatusColours';
import { getInitials, stringAvatar } from '../userProfile/ProfileData';
import { DiscussionEdit } from './DiscussionEdit';
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import { UploadListItem } from "../attachments/AttachmentListItem";
import { AttachmentDelete } from './AttachmentDelete';
import { QIRCompletedPDF, QIRCompletedPDFLink } from './QIRCompletedPDF';
import { QIRHistory } from './QIRHistory';

import styles from './Quality.module.css'

import { QIRTaskEdit } from './QIRTaskEdit';
import { QIRTaskView } from './QIRTaskView';

import { setToastMessage } from '../uiHelpers/toastSlice';

export const GetActionColours = (statusId: number) => {
  let colour = STATUS_COLOURS.Completed;
  if (statusId === QIR_STATUS.New) {
    colour = STATUS_COLOURS.New;
  }
  else if (statusId === QIR_STATUS.Approved) {
    colour = STATUS_COLOURS.Approved;
  }
  else if (statusId === QIR_STATUS.Assigned) {
    colour = STATUS_COLOURS.Assigned;
  }
  else if (statusId === QIR_STATUS.SM_Review) {
    colour = STATUS_COLOURS.SM_Review;
  }
  else if (statusId === QIR_STATUS.CEO_Review) {
    colour = STATUS_COLOURS.CEO_Review;
  }
  else if (statusId === QIR_STATUS.QM_Finalise) {
    colour = STATUS_COLOURS.QM_Finalise;
  }
  else if (statusId === QIR_STATUS.Rejected) {
    colour = STATUS_COLOURS.Rejected;
  }

  return { color: colour + 'ff', borderLeftColor: colour + 'ff', backgroundColor: colour + '20' };
};

const arrayUnique = (array: Array<User>) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id)
        a.splice(j--, 1);
    }
  }

  return a;
};

const propertyLabelStyle = { width: '40%' };
const propertyValueStyle = { width: '50%', fontWeight: '700' };

const GetNewDiscussion = (qirId: number, userId: string, userName: string): QIRDiscussion => {
  return { id: 0, qirid: qirId, userID: userId, user: userName, comment: '', createDate: undefined, createUser: undefined };
}

const StyledTextViewDiv = styled('div')({
  whiteSpace: 'pre-wrap',
  //  padding: '2px',
  //  border: '#f0f0f0 solid 1px',
  //  borderRadius: '4px',
  //  backgroundColor: '#f0f0f0'
});

const GetFileSizeText = (fileSize: number): string => {
  let fileSizeText: string = '';
  if (fileSize) {
    if (fileSize > (2 * 1024 * 1024)) {
      fileSizeText = (fileSize / (1024 * 1024)).toFixed(1).toString() + "M";
    }
    else if (fileSize > (2 * 1024)) {
      fileSizeText = (fileSize / 1024).toFixed(1).toString() + "K";
    }
    else {
      fileSizeText = fileSize.toString();
    }
  }
  return fileSizeText;
};

interface PdfParms {
  qirId: number;
  title: string;
  submitter: string;
  date: string;
  message: string;
};

export const QualityImprovementRequestEdit = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: userInfo } = useGetUserPrivilegesQuery();
  const [canEdit, setCanEdit] = useState(false);
  const [canAssign, setCanAssign] = useState(false);
  const [changed, setChanged] = useState(false);
  const [textEntered, setTextEntered] = useState(false);
  const [valid, setValid] = useState(false);
  const [commentSaved, setCommentSaved] = useState(false);

  const [accessToken, setAccessToken] = useState<string>('');

  const [statuses, setStatuses] = useState<SimpleDataItem[]>([] as SimpleDataItem[]);
  const [assignedToUser, setAssignedToUser] = useState<User>();
  //const [assignedToUserID, setAssignedToUserID] = useState<string>();
  const [qirStatusID, setQirStatusID] = useState<number>();
  const [taskNotes, setTaskNotes] = useState<string>('');
  const [taskNextInstructions, setTaskNextInstructions] = useState<string>('');
  const [dueDate, setDueDate] = useState<DateTime | null>();
  const [currentTask, setCurrentTask] = useState<QIRTask>();
  const [previousTask, setPreviousTask] = useState<QIRTask>();
  //const [showTaskEdit, setShowTaskEdit] = useState(false);

  const [rightPaneTabsValue, setRightPaneTabsValue] = useState<number>(0);

  const [showAddAttachment, setShowAddAttachment] = useState<boolean>(false);
  const [newDiscussion, setNewDiscussion] = useState<QIRDiscussion>();

  const { data: rawQir, refetch: refetchQIR } = useGetQualityImprovementRequestQuery(parseInt(id ?? '0'));
  const { data: discussions } = useGetDiscussionsQuery(parseInt(id ?? '0'));
  const { data: rawAttachments, refetch: refetchAttachments } = useGetQirAttachmentsQuery(parseInt(id ?? '0'));
  const { data: rawAllUsers } = useGetUsersQuery();
  const { data: rawLocalUsers } = useGetLocalUsersWithRolesQuery();
  const [updateQualityImprovementRequest, { isLoading: isUpdating }] = useUpdateQualityImprovementRequestMutation();

  //#region useMemos
  const qir = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    if (rawQir) {
      let newQir: QualityImprovementRequestDetail = { ...rawQir };
      if (newQir.createDate) newQir.createDate = DateTime.fromISO(newQir.createDate.toString(), { locale: 'en-NZ' });
      if (newQir.dueDate) newQir.dueDate = DateTime.fromISO(newQir.dueDate.toString(), { locale: 'en-NZ' });
      if (newQir.tasks && newQir.tasks.length > 0) {
        newQir.tasks = newQir.tasks.map((task: QIRTask) => {
          let newTask: QIRTask = { ...task };
          if (newTask.dueDate) newTask.dueDate = DateTime.fromISO(newTask.dueDate.toString(), { locale: 'en-NZ' });
          if (newTask.changeDate) newTask.changeDate = DateTime.fromISO(newTask.changeDate.toString(), { locale: 'en-NZ' });
          return newTask;
        })
      }
      return newQir;
    }
    return undefined;
  }, [rawQir]);

  const attachments = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    let items: QIRAttachment[] = [];
    if (rawAttachments) {
      items = rawAttachments.map((a) => {
        let newItem = { ...a };
        if (newItem.createDate) newItem.createDate = DateTime.fromISO(newItem.createDate.toString(), { locale: 'en-NZ' });
        newItem.fileSizeText = GetFileSizeText(newItem.fileSize);
        return newItem;
      });
    }
    return items;
  }, [rawAttachments]);

  const viewOnlyTasks = useMemo(() => {
    if (qir && qir.tasks && qirStatusID) {
      // TODO:  Filter out some tasks depending on the qir status...
      if (qir.statusID === QIR_STATUS.Assigned || qir.statusID === QIR_STATUS.SM_Review) {
        // When in assigned state or SM review, the user sees the current task in edit mode, so we don't need to see it here as well
        return qir.tasks.filter((t: QIRTask) => t.isCurrentTask === false);
      }
      return qir.tasks;
    }
    return undefined;
  }, [qir, qirStatusID]);

  const users = useMemo(() => {
    if (qirStatusID && rawAllUsers && rawLocalUsers) {
      if (qirStatusID === QIR_STATUS.New || qirStatusID === QIR_STATUS.QM_Finalise) {
        //return rawLocalUsers; // TEMP
        return rawLocalUsers.filter((u) => u.roleIDs && u.roleIDs.find(ur => ur === ROLE.Quality_Manager));
      }
      else if (qirStatusID === QIR_STATUS.Approved || qirStatusID === QIR_STATUS.SM_Review) {
        return rawLocalUsers.filter((u) => u.roleIDs && u.roleIDs.find(ur => ur === ROLE.Senior_Manager || ur === ROLE.Quality_Manager));
      }
      else if (qirStatusID === QIR_STATUS.Rejected || qirStatusID === QIR_STATUS.CEO_Review) {
        return rawLocalUsers.filter((u) => u.roleIDs && u.roleIDs.find(ur => ur === ROLE.CEO));
      }
      else if (qirStatusID === QIR_STATUS.Assigned) {
        return rawAllUsers;
      }
      return rawAllUsers;
    }
    return undefined;
  }, [qirStatusID, rawAllUsers, rawLocalUsers]);

  const allUsers = useMemo(() => {
    if (rawAllUsers && rawLocalUsers) {
      return arrayUnique(rawLocalUsers.concat(rawAllUsers)); // Might need to sort this list...
    }
    return undefined;
  }, [rawAllUsers, rawLocalUsers]);

  const pdfParameters = useMemo<PdfParms | undefined>(() => {
    if (qir && qirStatusID && qirStatusID === QIR_STATUS.Completed) {
      let parms = { qirId: qir.qirNumber, title: qir.title, submitter: qir.createUser, date: qir.createDate?.toLocaleString(DateTime.DATE_SHORT), message: '' };
      if (qir.statusID === QIR_STATUS.QM_Finalise && taskNotes && (taskNotes?.length ?? 0) > 0) {
        parms.message = taskNotes;
        return parms;
      }
      if (qir.statusID === QIR_STATUS.Completed && (qir.tasks?.length ?? 0) > 0) {
        const qmFinaliseTask = qir.tasks.find((t) => t.taskTypeID === TASK_TYPE.QM_Finalise);
        if (qmFinaliseTask && (qmFinaliseTask.notes?.length ?? 0) > 0) {
          parms.message = qmFinaliseTask.notes;
          return parms;
        }
      }
    }
    return undefined;
  }, [qir, qirStatusID, taskNotes]);

  const NextActionHint = useMemo(() => {
    let currentTaskTypeId: number = currentTask?.taskTypeID ?? 0;
    if (qir?.statusID === QIR_STATUS.Completed) {
      // For completed QIRs, the last QM_Finalise task is still flagged as current, but we don't want to pass this type to the hint getter...
      currentTaskTypeId = 0;
    }

    let isTextEntered = textEntered;
    if (qir?.statusID === QIR_STATUS.CEO_Review && qirStatusID === QIR_STATUS.SM_Review && assignedToUser?.id === previousTask?.assignedToID) {
      isTextEntered = commentSaved;
    }
    return GetNextActionHint(qir, currentTaskTypeId, qirStatusID ?? 0, assignedToUser, previousTask?.assignedToID, isTextEntered); // 'textEntered' was previously 'changed'
  }, [qir, assignedToUser, currentTask, previousTask, qirStatusID, textEntered, commentSaved]);
  //#endregion useMemos

  //#region useEffects
  useEffect(() => {
    if (qir) {
      //setAssignedToUserID(rawQir.assignedToID);
      setDueDate(qir.dueDate);
      setStatuses(GetApplicableStatuses(qir.statusID));
      if (allUsers) {
        setAssignedToUser(allUsers.find(u => u.id === qir.assignedToID));
        setQirStatusID(qir.statusID);
      }
      if (qir.tasks && qir.tasks.length > 0) {
        const activeTask = qir.tasks.find((t: any) => t.isCurrentTask === true);
        if (activeTask) {
          setTaskNotes(activeTask.notes);
          setCurrentTask(activeTask);
        }
      }
    }
  }, [qir, allUsers]);

  useEffect(() => {
    // TODO:  editing/assigning varies with permissions, QIR status and task assignee...
    //setCanEdit(hasUserAnyRole(userInfo));
  }, [userInfo]);

  // Data entry validataion
  useEffect(() => {
    if (qir && assignedToUser && qirStatusID) {
      setValid(IsTaskValid(qir, qirStatusID, assignedToUser, previousTask?.assignedToID, taskNotes, taskNextInstructions, commentSaved));
    }
  }, [qir, assignedToUser, qirStatusID, taskNotes, taskNextInstructions, commentSaved, previousTask]);

  useEffect(() => {
    if (qir && userInfo && userInfo.user) {
      setNewDiscussion(GetNewDiscussion(qir.id, userInfo.user.id, userInfo.user.userName));
    }
  }, [qir, userInfo]);


  useEffect(() => {
    let canBeAssigned = false;
    let canBeEdited = false;
    if (currentTask && userInfo && userInfo.user) {
      // Changing the assigned user (and the due date) is restricted...
      canBeAssigned = hasUserRole(userInfo, [ROLE.Quality_Manager, ROLE.System_Administrator]) || (hasUserAnyRole(userInfo) && userInfo.user.id === currentTask.assignedToID);
      // Editing relates to only the current task
      canBeEdited = hasUserRole(userInfo, [ROLE.Quality_Manager, ROLE.System_Administrator]) || userInfo.user.id === currentTask.assignedToID;
    }
    // Set the state values
    setCanAssign(canBeAssigned);
    setCanEdit(canBeEdited);

    if (canBeAssigned) {
      // Not sure how this is going to work...
      if (qirStatusID && users && users.length === 1) {
        // Only one possible user to assign, so assign them...
        setAssignedToUser(users[0]);
      }

      if (qir && qirStatusID) {
        if (qir.statusID !== qirStatusID) {
          // Target status different to current QIR status, so set a new due date, if appropriate
          if (qirStatusID === QIR_STATUS.Approved || qirStatusID === QIR_STATUS.Rejected || qirStatusID === QIR_STATUS.SM_Review
            || qirStatusID === QIR_STATUS.Assigned || qirStatusID === QIR_STATUS.CEO_Review || qirStatusID === QIR_STATUS.QM_Finalise) { // @T5285C, @T5309C
            // Set a due date of now + 5 working days
            setDueDate(AddWorkingDays(DateTime.now(), 5));
          }
        } else {
          // Status hasn't changed.  If necessary, set due date back to it's original value
          // *** NOT SURE if this is desired behaviour
          if (dueDate !== qir.dueDate) {
            setDueDate(qir.dueDate);
          }
        }
      }
    }
  }, [qir, qirStatusID, users, userInfo, currentTask]);

  // Set the previous task (in terms of change date?) - used in various other places...
  useEffect(() => {
    if (qir && qir.tasks && users) {
      let tasks = qir.tasks.slice(); // Take a copy of the tasks array as sorting mutates...
      if (qir.statusID === QIR_STATUS.SM_Review) {
        const investigationTask = tasks.sort((a, b) => b.changeDate?.toMillis() - a.changeDate?.toMillis()) // Most recent first
          .find(t => t.taskTypeID === TASK_TYPE.Investigation);
        if (investigationTask) {
          setPreviousTask(investigationTask);
        }
      }
      else if (qir.statusID === QIR_STATUS.Assigned) {
        const prevTask = tasks.sort((a, b) => b.changeDate?.toMillis() - a.changeDate?.toMillis()) // Most recent first
          .find(t => t.taskTypeID === TASK_TYPE.Initial_SM_Review || t.taskTypeID === TASK_TYPE.SM_Review);
        if (prevTask) {
          setPreviousTask(prevTask);
        }
      }
      else if (qir.statusID === QIR_STATUS.CEO_Review) {
        const prevTask = tasks.sort((a, b) => b.changeDate?.toMillis() - a.changeDate?.toMillis()) // Most recent first
          .find(t => t.taskTypeID === TASK_TYPE.SM_Review);
        if (prevTask) {
          setPreviousTask(prevTask);
        }
      }
    }
  }, [qir, users]);

  useEffect(() => {
    // Certain target status selections require a default assigned user to be selected
    if (qir && users) {
      if (qir.statusID === QIR_STATUS.SM_Review) {
        if (qirStatusID === QIR_STATUS.Assigned) {
          if (previousTask && previousTask.assignedToID) {
            const user = users.find(u => u.id === previousTask.assignedToID);
            if (user) {
              setAssignedToUser(user);
            }
          }
        }
      }
      else if (qir.statusID === QIR_STATUS.Assigned) {
        if (qirStatusID === QIR_STATUS.SM_Review) {
          if (previousTask && previousTask.assignedToID) {
            const user = users.find(u => u.id === previousTask.assignedToID);
            if (user) {
              setAssignedToUser(user);
            }
          }
        }
      }
      else if (qir.statusID === QIR_STATUS.CEO_Review) {
        if (qirStatusID === QIR_STATUS.SM_Review) {
          if (previousTask && previousTask.assignedToID) {
            const user = users.find(u => u.id === previousTask.assignedToID);
            if (user) {
              setAssignedToUser(user);
            }
          }
        }
      }
      // If current and target status is the same, and the assigned user has been changed
      if (qir.statusID === qirStatusID) {
        const user = users.find(u => u.id === qir.assignedToID);
        if (user) {
          setAssignedToUser(user);
          setChanged(false);
        }
      }
    }
  }, [qir, previousTask, qirStatusID, users]);

  useEffect(() => {
    async function fetchToken() {
      const token = await getAccessToken();
      setAccessToken(token);
    }
    fetchToken();

    // Always refetch the QIR in case it has been modified elsewhere - @T5286A
    refetchQIR();
  }, []);
  //#endregion

  //#region State helpers
  const onCommentSaved = (hasSaved: boolean) => {
    setCommentSaved(hasSaved);
  }

  const onNotesChanged = (text: string) => {
    setTaskNotes(text);
    setChanged(true);
    setTextEntered(text?.length > 0);
  }

  const onNextInstructionsChanged = (text: string) => {
    setTaskNextInstructions(text);
    setChanged(true);
    setTextEntered(text?.length > 0);
  }
  //#endregion

  //#region Other helpers
  //const GetNextActionHintMemo = useCallback((): ActionHint => {
  //  let currentTaskTypeId: number = currentTask?.taskTypeID ?? 0;
  //  if (qir?.statusID === QIR_STATUS.Completed) {
  //    // For completed QIRs, the last QM_Finalise task is still flagged as current, but we don't want to pass this type to the hint getter...
  //    currentTaskTypeId = 0;
  //  }

  //  return GetNextActionHint(qir, currentTaskTypeId, qirStatusID ?? 0, assignedToUser, textEntered); // 'textEntered' was previously 'changed'
  //}, [qir, assignedToUser, currentTask, qirStatusID, textEntered]);

  //#endregion

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleClose = () => {
    navigate("/");
  }

  const handleSave = async () => {
    if (qir && assignedToUser && qirStatusID && dueDate) {
      const parm: UpdateQualityImprovementRequestParm = {
        id: qir.id,
        statusID: qirStatusID,
        assignedToID: assignedToUser.id,
        dueDate: dueDate,
        taskNotes: taskNotes,
        taskNextInstructions: taskNextInstructions,
      };

      const res: any = await updateQualityImprovementRequest(parm);
      // If all is well there should be some data returned (with a return code)
      const newQirId: number = res.data;
      if (newQirId !== undefined && newQirId > 0) {
        displayToastMessage("info", "QIR saved", "QIR saved successfully");
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error saving QIR", errorText);
      }
    }
    //displayToastMessage("warning", "QIR Save", "This feature is not yet implemented");
  };

  //#region Tab panel
  const handleRightPaneTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setRightPaneTabsValue(newValue);
  };
  //#endregion

  //#region Attachments
  const [selectedItem, setSelectedItem] = useState<Partial<QIRAttachment> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [canDeleteAttachment, setCanDeleteAttachment] = useState<boolean>(true);

  const handleAttachmentDelete = (item: QIRAttachment) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const attachmentColumns: GridColDef<QIRAttachment>[] = [
    {
      field: 'attachment', headerName: 'Name', width: 280
      , renderCell: ({ row }: GridRenderCellParams<QIRAttachment>) => (
        <Stack direction='row' sx={{ marginBottom: '4px' }}>
          <InsertDriveFileIcon sx={{ fontSize: '18px', color: 'gray', margin: '0 6px 0 0' }} />
          <a href={row.url} target="_blank" rel="noopener noreferrer"><span>{row.attachment}</span></a>
        </Stack>
      )
    },
    {
      field: 'fileSize', headerName: 'Size', type: 'number', width: 90
      , renderCell: ({ row }: GridRenderCellParams<QIRAttachment>) => (
        <span>{row.fileSizeText}</span>
      )
    },
    {
      field: 'createDate', headerName: 'Date Attached', width: 180
      , renderCell: ({ row }: GridRenderCellParams<QIRAttachment>) => (
        <span>{row.createDate?.toLocaleString(DateTime.DATETIME_SHORT)}</span>
      )
    },
    {
      field: ' ', headerName: ' ', sortable: false, filterable: false, disableColumnMenu: true, width: 60, align: 'center', headerAlign: 'center'
      , renderCell: ({ row }: GridRenderCellParams<QIRAttachment>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title='Delete attachment' enterDelay={1000}>
            <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
              <IconButton disabled={!canDeleteAttachment} color="primary" size="small"
                onClick={() => handleAttachmentDelete(row)}>
                <DeleteIcon sx={{ fontSize: '1.2em' }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      ),
    },

  ];

  const { processPending } = useUploady();
  const abortAll = useAbortAll();

  const [newAttachments, setNewAttachments] = useState([]);

  const handleNewAttachmentDeleted = (item: any) => {
    const idx = newAttachments?.findIndex((a: any) => a.id === item?.id);
    if (idx > -1) {
      let revisedAttachments = newAttachments.slice(); // Copy the array
      revisedAttachments.splice(idx, 1); // Remove the deleted item (in-place)
      setNewAttachments(revisedAttachments);
    }
  };

  useBatchAddListener((batch: any) => {
    setNewAttachments((newAttachments: any) => newAttachments.concat(batch.items));
  });

  useRequestPreSend((props: any) => {
    return {
      options: {
        destination: {
          url: "/api/data/AddQirAttachments/" + qir?.id,
          headers: { "Authorization": `Bearer ${accessToken}` }
        }
      }
    };

  });

  useBatchFinalizeListener((batch: any) => {
    refetchAttachments();
    setShowAddAttachment(false);
  });

  const handleShowAddAttachment = () => {
    if (showAddAttachment === true) {
      // Currently showing, so this is a cancel, in which case we need to abort any pending upload batches
      abortAll();
      setNewAttachments([]);
    }
    setShowAddAttachment(!showAddAttachment);
  };

  const handleAttachmentUpload = () => {
    processPending();
  };

  const GetAttachmentTabLabel = useCallback((): React.ReactElement => {
    if (attachments && attachments.length > 0) {
      return <Stack direction="row" sx={{ color: 'blue', fontWeight: 'bold' }}><AttachFileIcon /><span style={{ fontSize: '14px' }}>({attachments.length})</span></Stack>;
    }
    return <AttachFileIcon />;
  }, [attachments]);

  const AttachmentUpload = (props: any) => {

    const StyleUploadButton = styled(UploadButton)({
      fontFamily: 'Open Sans',
      margin: '4px',
    });

    const ListContainer = styled('section')({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginTop: '4px',
    });

    return (
      <Grid container>
        <Grid xs={12}>
          <div style={{ marginTop: '8px' }}>
            <Stack>
              <div>
              </div>
              <UploadDropZone
                onDragOverClassName="drag-over"
                grouped={true}
                maxGroupSize={3}
                autoUpload={false}
                className={styles.dropZone}
              >
                <StyleUploadButton autoUpload={false} grouped={true}
                  extraProps={{ type: 'button' }}>Select File(s)</StyleUploadButton>
                <span style={{ margin: '4px', fontSize: '13px' }}>or drag & drop file(s) here</span>
              </UploadDropZone>
            </Stack>
          </div>
        </Grid>
        {newAttachments && newAttachments.length > 0 ?
          <Grid xs={12}>
            <Grid xs={12}>
              <Button variant="outlined" className="gridButton" sx={{ width: '100%', margin: '8px 0 0 0' }} onClick={handleAttachmentUpload}>Upload Selected Files</Button>
            </Grid>
            <Grid xs={12}>
              <ListContainer>
                {newAttachments.map((item: any) => (
                  <UploadListItem key={item.id} item={item} onAttachmentDelete={handleNewAttachmentDeleted} />
                ))}
              </ListContainer>
            </Grid>
          </Grid>
          : <></>}

      </Grid>
    );
  }

  //#endregion

  //#region PDF
  //#endregion

  return (
    <>
      {qir && qir.id ?
        <>
          <Grid container spacing={1}>
            { /* Title and headings area */}
            <Grid xs={6}>
              <span className='pageHeader'>{qir.qirNumber} {qir.title}</span>
            </Grid>
            <Grid xs={6}>
              <Stack direction="row" gap={2} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                {!NextActionHint?.isEmptyHint ?
                  <div className={styles.nextAction}>
                    <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                      <InfoIcon />
                      <div style={{ paddingRight: '16px' }}>{rawQir ? NextActionHint.hint : <></>}</div>
                    </Stack>
                  </div>
                  : <></>}
                {pdfParameters ? 
                  <QIRCompletedPDFLink qirId={pdfParameters.qirId?.toString()}
                    title={pdfParameters.title}
                    submitter={pdfParameters.submitter}
                    date={pdfParameters.date}
                    message={pdfParameters.message} />
                  : <></>}
                <Button variant="contained" className="gridButton" onClick={handleSave} disabled={!changed || !valid}>Save & Close</Button>
                <Button variant="outlined" className="gridButton" sx={{ width: '36px', minWidth: '36px' }} onClick={handleClose}><ClearIcon sx={{ fontSize: '16px' }} /></Button>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack direction="row" gap={1}>
                <Avatar {...stringAvatar((assignedToUser ? assignedToUser.name : 'Unknown'), "24px", "0.8em")}>{assignedToUser && getInitials(assignedToUser.name)}</Avatar>
                <Autocomplete sx={{ width: 260 }} className={NextActionHint?.highlight?.user ? styles.hintHighlight : ''}
                  options={users ?? []}
                  getOptionLabel={(option: User) => (option && option.name ? option.name : '')}
                  value={allUsers && assignedToUser ? allUsers.find((u: User) => u.id === assignedToUser.id) : {} as User}
                  onChange={(e, value) => {
                    if (value) {
                      setAssignedToUser(value);
                      setChanged(true);
                    }
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{ ...params.InputProps }}
                      variant="standard"
                      label=""
                      placeholder="Assigned user"
                    />
                  )}
                  isOptionEqualToValue={(opt: User, val: Partial<User>) => { return opt?.id === val?.id; }}
                  disabled={!canAssign}
                />
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>State</span>
                <div style={{ backgroundColor: GetActionColours(qirStatusID ?? QIR_STATUS.New).color, borderRadius: '6px', height: '12px', width: '12px' }}></div>
                <Autocomplete sx={{ width: 180 }} className={NextActionHint?.highlight?.state ? styles.hintHighlight : ''}
                  options={statuses ?? []}
                  getOptionLabel={(option: SimpleDataItem) => (option && option.name ? option.name : '')}
                  value={statuses && qirStatusID ? statuses.find((u: SimpleDataItem) => u.id === qirStatusID) : {} as SimpleDataItem}
                  onChange={(e, value) => {
                    if (value) {
                      setQirStatusID(value.id);
                      setChanged(true);
                    }
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{ ...params.InputProps }}
                      variant="standard"
                      label=""
                      placeholder="Target status"
                    />
                  )}
                  isOptionEqualToValue={(opt: SimpleDataItem, val: Partial<SimpleDataItem>) => { return opt?.id === val?.id; }}
                  disabled={!canEdit}
                />
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Location</span>
                <span style={propertyValueStyle}>{qir.location}</span>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Submitted by</span>
                <span style={propertyValueStyle}>{qir.createUser}</span>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Submitted for</span>
                <span style={propertyValueStyle}>{qir.submittedFor}</span>
              </Stack>
            </Grid>

            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Due</span>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker value={dueDate}
                    onChange={(value) => { setDueDate(value); setChanged(true); }}
                    slotProps={{ textField: { variant: 'standard', sx: { marginLeft: '20px', width: '180px' } } }}
                    format="dd/MM/yyyy"
                    disabled={!canAssign}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Operation</span>
                <span style={propertyValueStyle}>{qir.operation}</span>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack direction="row" gap={1} sx={{ alignItems: 'center', height: '100%' }}>
                <span style={propertyLabelStyle}>Date submitted</span>
                <span style={propertyValueStyle}>{qir.createDate.toLocaleString(DateTime.DATETIME_SHORT)}</span>
              </Stack>
            </Grid>
            <Grid xs={3}>
            </Grid>

            { /* Main area - left pain: In its own container grid... */}
            <Grid xs={7} sx={{ marginTop: '20px' }}>
              { /* Using a Tabs component with single tab, to match style of right pane... */}
              <Tabs value={0} sx={{ marginRight: '20px', width: 'auto' }} TabIndicatorProps={{ sx: { height: '1px', backgroundColor: '#c0c0c0' }}}>
                <Tab className="tabButtonMaxWidth" id="0" label="Description" />
              </Tabs>
              <Grid xs={12} sx={{ marginTop: '8px' }}>
                <StyledTextViewDiv style={{ marginRight: '20px' }}>{qir.description}</StyledTextViewDiv>
              </Grid>
              <Grid xs={12}>
                <Stack sx={{ marginRight: '20px', marginTop: '16px' }}>
                  {/* Historic, non-current tasks will be listed first (?) */}
                  {viewOnlyTasks ?
                    viewOnlyTasks.map((task: QIRTask) => (
                      <QIRTaskView task={task} qirStatusId={qir.statusID} />
                    ))
                    :
                    <></>
                  }
                  {currentTask && qirStatusID && qir && assignedToUser ?
                    <QIRTaskEdit qir={qir} assignedToUser={assignedToUser} task={currentTask} previousTask={previousTask} currentQirStatusId={qir.statusID}
                      nextQirStatusId={qirStatusID} onNotesChange={onNotesChanged} onNextInstructionsChange={onNextInstructionsChanged}
                      highlightNotes={NextActionHint?.highlight?.note} highlightInstructions={NextActionHint?.highlight?.instruction}                 />
                    : <></>
                  }
                </Stack>
              </Grid>
            </Grid>

            { /* Main area - right pain: In its own container grid... */}
            <Grid xs={5} sx={{ marginTop: '20px' }}>
              <Tabs value={rightPaneTabsValue} TabIndicatorProps={{ sx: { height: '1px', backgroundColor: '#c0c0c0' } }} onChange={handleRightPaneTabsChange}>
                <Tab className="tabButton" id="0" label="Discussion" />
                <Tab icon={<HistoryIcon />} className="tabButton" id="1" />
                <Tab className="tabButton" id="3" label={GetAttachmentTabLabel()} />
              </Tabs>
              <Grid xs={12} sx={{ marginTop: '8px' }}>
                {rightPaneTabsValue === 0 ?
                  <Grid xs={12}>
                    <Stack>
                      {newDiscussion ?
                        <DiscussionEdit canEdit={true} discussion={newDiscussion} userInfo={userInfo} onSaved={onCommentSaved} isHighlit={NextActionHint?.highlight?.comment} />
                        :
                        <></>
                      }
                      {userInfo && userInfo.user && qir && discussions ?
                        discussions.map((discussion: QIRDiscussion) =>
                          <DiscussionEdit discussion={discussion}
                            canEdit={qir.statusID !== QIR_STATUS.Completed && userInfo.user.id === discussion.userID}
                            userInfo={userInfo} isHighlit={false} />
                          //<DiscussionEdit discussion={discussion} canEdit={false} userInfo={userInfo} />
                        )
                        : <></>}
                    </Stack>
                  </Grid>
                  : <></>}
                {rightPaneTabsValue === 1 ?
                  <Grid xs={12}>
                    {qir ?
                      <QIRHistory id={qir.id}/>
                      : <></>}
                  </Grid>
                  : <></>}
                {rightPaneTabsValue === 2 ?
                  <Grid xs={12}>
                    <Stack>
                      <Button className="gridButton" variant="outlined" startIcon={showAddAttachment ? <ClearIcon /> : <AddIcon />} sx={{ width: '180px' }}
                        onClick={handleShowAddAttachment}>
                        {showAddAttachment ? <span>Cancel</span> : <span>Add attachment</span>}
                      </Button>
                      {showAddAttachment ?
                        <AttachmentUpload />
                        : <></>}
                      {attachments && attachments.length > 0 && !showAddAttachment ?
                        <DataGrid rows={attachments} columns={attachmentColumns} rowHeight={GridRowHeight} density="compact" sx={sxNoCellBorder} getRowId={(row) => row.id} />
                        : <></>}
                    </Stack>
                  </Grid>
                  : <></>}
              </Grid>

            </Grid>

          </Grid>

          <AttachmentDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} attachment={selectedItem} />
        </>
        :
        <div><span>Loading...</span></div>}
    </>
  );

}