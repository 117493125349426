import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridRenderCellParams, GridCellParams, MuiEvent, GridColDef } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { AlertColor } from "@mui/material/Alert";

import { useGetUserPrivilegesQuery, useGetLocalUsersWithRolesQuery } from "../api/apiSlice";
import { hasUserRole } from '../userProfile/userRoleHelper';

import { ToastMessageValue } from '../uiHelpers/ToastMessage';
import { setToastMessage } from '../uiHelpers/toastSlice';
import { ROLE, User } from "../api/types";
import { sxNoCellBorder, GridRowHeight } from '../uiHelpers/DataGridStyling';
import styles from './UserAccess.module.css';

import { UserRoleEdit } from './UserRoleEdit';
import { UserRoleDelete } from './UserRoleDelete';

export const UserHome = (props: any) => {
  const dispatch = useDispatch();

  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<Partial<User> | null>();
  const [showItemDelete, setShowItemDelete] = useState<boolean>(false);
  const [showItemEdit, setShowItemEdit] = useState<boolean>(false);

  const { data: rawUsers, error, isLoading } = useGetLocalUsersWithRolesQuery();
  const { data: userInfo } = useGetUserPrivilegesQuery();

  useEffect(() => {
    if (userInfo) {
      setCanUpdate(hasUserRole(userInfo, [ROLE.Quality_Manager]));
      setCanDelete(hasUserRole(userInfo, [ROLE.Quality_Manager]));
    }
  }, [userInfo])

  const users = useMemo(() => {
    let records: User[] = [];
    if (rawUsers) {
      records = rawUsers.filter((r) => r.name.toLowerCase().includes(filter.toLowerCase()));
    }
    return records;
  }, [rawUsers, filter]);

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  const handleGridCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
  }

  const handleAdd = () => {
    //displayToastMessage("warning", "Add User Role", "This feature is not yet implemented");
    setSelectedItem({ id: '', name: '' } as Partial<User>);
    setShowItemEdit(true);
  };

  const handleEdit = (item: User) => {
    //displayToastMessage("warning", "Edit User Role", "This feature is not yet implemented");
    if (item) {
      setSelectedItem(item);
      setShowItemEdit(true);
    }
  };

  const handleDelete = (item: User) => {
    if (item) {
      setSelectedItem(item);
      setShowItemDelete(true);
    }
  };

  const columns: GridColDef<User>[] = [
    { field: 'name', headerName: 'Name', width: 240 },
    { field: 'rolesText', headerName: 'Role(s)', width: 340 },
    {
      field: ' ', headerName: 'Actions', sortable: false, filterable: false, disableColumnMenu: true, width: 100, align: 'center', headerAlign: 'center'
      , renderCell: ({ row }: GridRenderCellParams<User>) => (
        <Stack direction='row' gap={2}>
          <Tooltip title="Edit" enterDelay={1000}>
            <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
              <IconButton disabled={!canUpdate} color="primary" size="small"
                onClick={() => handleEdit(row)}>
                <EditIcon sx={{ fontSize: '1.2em' }} />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title='Delete' enterDelay={1000}>
            <span> {/* The span is required to remove the warning that the tooltip content is disabled... */}
              <IconButton disabled={!canDelete} color="primary" size="small"
                onClick={() => handleDelete(row)}>
                <DeleteIcon sx={{ fontSize: '1.2em' }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <span className='pageHeader'>User Roles</span>
        </Grid>
        <Grid sx={{ display: 'flex ' }} xs={12}>
          <Stack direction="row" gap={2}>
            <Button variant="contained" className="gridButton"
              disabled={false} onClick={handleAdd}>Add</Button>

            <TextField sx={{ verticalAlign: 'bottom', minWidth: '240px', marginTop: 'auto' }}
              placeholder="search..."
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ margin: '4px' }}>
                    <SearchIcon sx={{ fontSize: '1.4em', color: '#c0c0c0' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton size='small'
                      onClick={() => setFilter('')}
                    >
                      <CloseIcon sx={{ fontSize: '0.8em', color: filter && filter.length > 0 ? '#c0c0c0' : 'transparent' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </Grid>

        <Grid xs={12}>
          {error ? (
            <Typography sx={{ marginLeft: '8px' }}>Oh no, there was an error</Typography>
          ) : isLoading ? (
            <Typography sx={{ marginLeft: '8px' }}>Loading...</Typography>
          ) : users ? (
            <div style={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
              <div style={{ flexGrow: '1' }}>
                <DataGrid rows={users} columns={columns} rowHeight={GridRowHeight} sx={sxNoCellBorder} getRowId={(row) => row.id}
                  onCellClick={handleGridCellClick} />
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <UserRoleEdit open={showItemEdit} onClose={() => setShowItemEdit(false)} user={selectedItem} />
      <UserRoleDelete open={showItemDelete} onClose={() => setShowItemDelete(false)} user={selectedItem} />

    </>
  );
};