import React from "react";
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { SignInButton } from "../signin/SignInButton";
import styles from './LandingPage.module.css';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const LandingPage = (props: any) => {

    return (
        <>
            <div className={styles.navbarColour} style={{ width: '100%', height: '100vh' }}>
                <Grid container >
                    <Grid xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '12px' }}>
                            <SignInButton />
                        </div>
                    </Grid>
                    <Grid xs={12}>
                        <div className={styles.titleDiv} style={{ height: 'calc(100vh - 120px)' }}>
                            <Stack style={{ display: 'flex', width: '55%', minWidth: '400px'} }>
                                <span className={styles.title}>Qube Ports NZ Quality Improvement Request</span>
                                <span className={styles.subtitle} style={{ marginLeft: '44px', marginTop: '4px' }}>Suggestions to improve our work place.</span>
                                <span className={styles.subtext} style={{ marginTop: '20px' }}>
                                        Qube is committed to meeting or exceeding customer requirements and creating total
                                        customer satisfaction with on time performance, order accuracy and maintaining the
                                        highest quality of service. This is accomplished through our commitment to
                                        continual improvement of our people, our suppliers, and our processes
                                </span>
                            </Stack>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};