import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid, GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';

import { HistoryRecord, StateHistoryRecord, QIR_STATUS } from '../api/types';
import { useGetQirHistoryQuery, useGetQirStateHistoryQuery } from '../api/apiSlice';

import { sxNoCellBorder } from '../uiHelpers/DataGridStyling';
import { GetActionColours } from './QualityImprovementRequestEdit';

const StyledState = styled('div')({ margin: "auto 10px !important", height: "min-content" });
const StyledArrow = styled('div')({ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "20px" });
const StyledArrowTail = styled('div')({ height: "40%", backgroundColor: "#396bff", flexGrow: "1" });
const StyledArrowHead = styled('div')({ height: "0", width: "0", borderStyle: "solid", borderWidth: "10px 0 10px 10px", borderColor: "transparent transparent transparent #396bff" });

export const QIRHistory = (props: any) => {

  const { data: rawQirHistory } = useGetQirHistoryQuery(props.id);
  const { data: rawQirStateHistory } = useGetQirStateHistoryQuery(props.id);

  const qirHistory = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    let items: HistoryRecord[] = [];
    if (rawQirHistory) {
      items = rawQirHistory.map((a) => {
        let newItem = { ...a };
        if (newItem.date) newItem.date = DateTime.fromISO(newItem.date.toString(), { locale: 'en-NZ' });
        return newItem;
      });
    }
    return items;
  }, [rawQirHistory]);

  const qirStateHistory = useMemo(() => {
    // NOTE: Due to an issue with Chrome appearing to use a default locale 'en-US', regardless of the system locale, we force a locale of 'en-NZ'
    // during date parsing
    let items: StateHistoryRecord[] = [];
    if (rawQirStateHistory) {
      items = rawQirStateHistory.map((a) => {
        let newItem = { ...a };
        if (newItem.date) newItem.date = DateTime.fromISO(newItem.date.toString(), { locale: 'en-NZ' });
        return newItem;
      });
    }
    return items;
  }, [rawQirStateHistory]);

  const historyColumns: GridColDef<HistoryRecord>[] = [
    {
      field: 'message', headerName: 'Event', width: 400
    },
    {
      field: 'date', headerName: 'Timestamp', width: 180
      , renderCell: ({ row }: GridRenderCellParams<HistoryRecord>) => (
        <span>{row.date?.toLocaleString(DateTime.DATETIME_SHORT)}</span>
      )
    },
  ];

  return (
    <>
      <Stack>
        <Typography variant="h6" sx={{ margin: '8px 4px 0 4px' }}>State Graph</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {qirStateHistory && qirStateHistory.length > 0 ?
            qirStateHistory.map((h: StateHistoryRecord) =>
              <Stack direction='row' spacing={1} sx={{ margin: '8px 4px' }}>
                <Stack>
                  <Typography variant="body2" sx={{ margin: "0 8px" }}>{h.date?.toLocaleString(DateTime.DATE_SHORT)} {h.date?.toLocaleString(DateTime.TIME_24_SIMPLE)}</Typography>
                  <StyledArrow>
                    <StyledArrowTail />
                    <StyledArrowHead />
                  </StyledArrow>
                  <Typography variant="body2" sx={{ margin: "0 8px" }}>{h.user}</Typography>
                </Stack>
                <StyledState>
                  <Stack direction='row' spacing={1} sx={{ alignItems: 'center', padding: "4px 8px", backgroundColor: '#e8e8e8' }}>
                    <div style={{ backgroundColor: GetActionColours(h.statusID ?? QIR_STATUS.New).color, borderRadius: '6px', height: '12px', width: '12px' }}></div>
                    <Typography sx={{ fontWeight: '500'} }>{h.status}</Typography>
                  </Stack>
                </StyledState>
              </Stack>)
            : <></>}
        </Box>
        {qirHistory && qirHistory.length ?
          <>
            <Typography variant="h6" sx={{ margin: '8px 4px 0 4px' }}>History</Typography>
            <DataGrid rows={qirHistory} columns={historyColumns} density="compact" sx={sxNoCellBorder} getRowId={(row) => row.id} />
          </>
          : <></>}
      </Stack>
    </>
  );
}