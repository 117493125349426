import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { DateTime } from 'luxon';

import { QIRTask, TASK_TYPE, QIR_STATUS } from '../api/types';
import { GetTaskViewParameters, TaskViewParameters } from './taskHelper';

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#e0e0e0',
  fontWeight: 700,
  minHeight: 32,
  maxHeight: 32,
  '&.Mui-expanded': {
    minHeight: 32,
    maxHeight: 32,
    backgroundColor: '#e0e0e0',
    margin: 0,
  },
});

const StyledTextViewDiv = styled('div')({
  whiteSpace: 'pre-wrap',
//  padding: '2px',
//  border: '#f0f0f0 solid 1px',
//  borderRadius: '4px',
//  backgroundColor: '#f0f0f0'
});

export interface QIRTaskViewProps {
  task: QIRTask;
  qirStatusId: number;
};

export const QIRTaskView = (props: QIRTaskViewProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const viewParms = useMemo((): TaskViewParameters | undefined => {
    if (props && props.task) {
      return GetTaskViewParameters(props.task.taskTypeID ?? 0, props.qirStatusId ?? 0);
    }
    return undefined;
  }, [props]);

  useEffect(() => {
    if (props && props.task) {
      if (props.task.taskTypeID === TASK_TYPE.Initial_SM_Review) {
        if (props.qirStatusId === QIR_STATUS.New || props.qirStatusId === QIR_STATUS.Approved || props.qirStatusId === QIR_STATUS.Assigned) {
          setExpanded(true);
        }
        else {
          setExpanded(false);
        }
      }
    }
  }, [props]);

  return (
    <>
      {viewParms && viewParms.show && props && props.task ?
        <Accordion disableGutters expanded={expanded} onChange={(e: any, expanded: boolean) => setExpanded(expanded)}
          sx={{ marginBottom: '8px' }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>{viewParms.title}</span>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {viewParms.showAssignedTo ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.assignedToLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <span>{props.task.assignedTo}</span>
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showInstructions ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.instructionsLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <StyledTextViewDiv>{props.task.instructions}</StyledTextViewDiv>
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showDueDate ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.dueDateLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <span>{props.task.dueDate?.toLocaleString(DateTime.DATE_SHORT)}</span>
                  </Grid>
                </>
                : <></>
              }
              {viewParms.showNotes ?
                <>
                  <Grid xs={4}>
                    <span>{viewParms.notesLabel}</span>
                  </Grid>
                  <Grid xs={8}>
                    <StyledTextViewDiv>{props.task.notes}</StyledTextViewDiv>
                  </Grid>
                </>
                : <></>
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
        : <></>}
    </>
  )
}