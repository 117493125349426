import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { User } from '../api/types';
import { useDeleteAllUserRolesMutation } from '../api/apiSlice';
import { ToastMessageValue } from '../uiHelpers/ToastMessage';

import { setToastMessage } from '../uiHelpers/toastSlice';

import styles from './UserAccess.module.css'

export const UserRoleDelete = (props: any) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<User | null>(null);

  const [deleteAllUserRoles] = useDeleteAllUserRolesMutation();

  const displayToastMessage = (severity: AlertColor, header: string, body: string) => {
    // NOTE: The toast message belongs to the top level PageLayout component
    dispatch(setToastMessage({ severity: severity, header: header, body: body } as ToastMessageValue));
  };

  // Note that the parent component controls our visibility
  const handleClose = () => props.onClose();
  const handleSave = async (delUser: Partial<User>) => {
    if (delUser) {
      const res: any = await deleteAllUserRoles(delUser.id ?? '');
      // If all is well there should be some data returned (with a return code)
      const returnCode = res.data;
      if (returnCode !== undefined) {
        displayToastMessage("info", "User roles removed", "All roles removed from user " + (user ? user.name : ''));
        handleClose();
      } else {
        const errorText = res.error && res.error.data ? res.error.data : "Unknown error";
        displayToastMessage("error", "Error removing user roles", errorText);
      }
    }
  };

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { width: "440px" } }}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >
          <span className="dialogHeader">Delete User Roles</span>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '16px 0', margin: 'auto' }}>
            <Formik initialValues={props.user} onSubmit={handleSave}>
              {({ errors, handleSubmit, handleChange, touched, values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <span>Are you sure you want to delete all roles from this user?</span>
                    </Grid>
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-end" style={{ marginTop: '26px' }}>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          type="button"
                          color="primary"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                          className={styles.gridButton}
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <span>Delete</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );

};